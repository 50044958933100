import {
  Box,
  Button,
  Center,
  Input,
  Flex,
  FormControl,
  FormLabel,
  Link as ChakraLink,
  UnorderedList as ChakraList,
  ListItem as ChakraListItem,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as ReactRouterLink } from 'react-router-dom';

import { environment } from '../../../../../environments/environment';
import { BaseFormProps, BrandSpinner, WindowAlert } from '../../../../components';
import { getSuitabilityQuestions, saveSuitabilityAnswers } from '../../../../lib/apis/suitability';
import { IBaseResponse } from '../../../../lib/interfaces';
import {
  SuitabilityAnswer,
  SuitabilityAnswerRequest,
  SuitabilityAnswerResponse,
  SuitabilityQuestion,
  SuitabilityQuestionChoice,
  SuitabilityQuestionResponse,
} from '../../../../lib/types';

// Return only success or failure
interface IFormResults {
  verdict: boolean;
}

// Add index and answer to each question for the form input
interface IFormQuestion extends SuitabilityQuestion {
  index: number;
  answerText: string | null;
}

// TODO: Migrate to Register FieldItems to support array of questions within a single form
interface IFormInput {
  suitabilityQuestions: IFormQuestion[];
}

const defaultApproveErrorMessage = 'We are unable to validate your responses.';
const defaultLoadErrorMessage = 'We are unable to download the questionnaire.';
const defaultSaveErrorMessage = 'We are unable to save your responses.';

export const SuitabilityForm = ({ onSuccess }: BaseFormProps<IFormResults>) => {
  const {
    handleSubmit,
    reset,
    formState: { isSubmitted },
  } = useForm<IFormInput>();

  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [questionnaireForm, setQuestionnaireForm] = useState<IFormInput>();
  const [suitabilityMessages, setSuitabilityMessages] = useState<string[] | null>();

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  // Triggered more than once - use a reference variable to determine if component is mounted
  React.useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    // React advises to declare the async function directly inside useEffect
    async function loadSuitabilityQuestionnaire() {
      try {
        const response = await getSuitabilityQuestions();
        if (response.successful === true && response.payload && response.payload.suitabilityQuestions) {
          setQuestionnaireForm({
            suitabilityQuestions: response.payload.suitabilityQuestions.map((question, index) => {
              const result: IFormQuestion = {
                index: index,
                answerText: null,
                ...question,
              };
              return result;
            }),
          });
        }
        setIsLoading(false);
      } catch (error) {
        setErrorMessage(
          (error as AxiosError<IBaseResponse<SuitabilityQuestionResponse>>)?.response?.data?.errorMessages?.toString() || defaultLoadErrorMessage
        );
        setHasError(true);
        setIsLoading(false);
      }
    }

    loadSuitabilityQuestionnaire();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Save responses to suitability questionnaire
  const onSubmit = async (values: IFormInput) => {
    if (!questionnaireForm) return false;

    setIsSubmitting(true);

    // Nothing in values until IFormInput is wired-up differently
    const suitabilityAnswerRequest: SuitabilityAnswerRequest = { answers: [] };
    questionnaireForm.suitabilityQuestions.forEach((question) => {
      const answer: SuitabilityAnswer = {
        suitabilityQuestionId: question.suitabilityQuestionId,
        answerText: question.answerText ?? '',
      };
      suitabilityAnswerRequest.answers.push(answer);
    });

    // Save answers, return if success
    try {
      const response = await saveSuitabilityAnswers(suitabilityAnswerRequest);
      if (!response.successful) {
        setErrorMessage(response.errorMessages.length === 0 ? defaultSaveErrorMessage : response.errorMessages.toString());
        setHasError(true); // triggers window alert
      } else if (!response.payload.isApproved) {
        if (!response.payload.failureReasons || response.payload.failureReasons.length === 0) {
          setErrorMessage(defaultApproveErrorMessage);
          setHasError(true); // triggers window alert
        } else {
          setSuitabilityMessages(response.payload.failureReasons); // display errors and allow to retry
        }
        setHasError(true); // triggers window alert
      } else {
        // Success! continue
        onSuccess({ verdict: true });
        setIsSubmitting(false);
        return true;
      }
      setIsSubmitting(false);
    } catch (error) {
      setErrorMessage(
        (error as AxiosError<IBaseResponse<SuitabilityAnswerResponse>>)?.response?.data?.errorMessages?.toString() || defaultSaveErrorMessage
      );
      setHasError(true);
      setIsSubmitting(false);
    }
    reset(); // should enable button
    return false;
  };

  // function to update Questionnaire answer
  function updateQuestionnaireValue(questionIndex: number, answerText: string | null) {
    if (!questionnaireForm) return;
    const transientQuestionnaireForm = questionnaireForm;
    const question = transientQuestionnaireForm.suitabilityQuestions[questionIndex];
    question.answerText = answerText;
    transientQuestionnaireForm.suitabilityQuestions[questionIndex] = question;
    setQuestionnaireForm(transientQuestionnaireForm);
  }

  // Format answers as options in a drop-down
  function formatMultipleChoiceItem(questionIndex: number, choice: SuitabilityQuestionChoice) {
    return (
      <option
        key={choice.suitabilityQuestionChoiceId}
        value={choice.suitabilityQuestionChoiceId}
        onChange={(event) => updateQuestionnaireValue(questionIndex, choice.choiceText)}
      >
        <Text color="black" textStyle={{ base: 'text-xs-regular', lg: 'text-md-regular' }}>
          {choice.choiceText}
        </Text>
      </option>
    );
  }

  // Format answers as radio buttons
  function formatMultipleChoiceRadio(questionIndex: number, choice: SuitabilityQuestionChoice) {
    return (
      <Radio
        key={choice.suitabilityQuestionChoiceId}
        value={choice.suitabilityQuestionChoiceId}
        onChange={(event) => updateQuestionnaireValue(questionIndex, choice.choiceText)}
      >
        <Text color="black" textStyle={{ base: 'text-xs-regular', lg: 'text-md-regular' }}>
          {choice.choiceText}
        </Text>
      </Radio>
    );
  }

  // TODO: Seperate into widgets
  function formatSuitabilityQuestion(question: SuitabilityQuestion, questionIndex: number) {
    const questionId = question.suitabilityQuestionId;
    if (question.isMultipleChoice && question.choices) {
      if (question.choices.length > 3) {
        return (
          <FormControl key={`fc${questionIndex}`} mt={questionIndex === 0 ? '0px' : '36px'} isRequired>
            <FormLabel htmlFor={questionId}>
              <Text as="span" color="black" textStyle={{ base: 'text-xs-regular', lg: 'text-md-regular' }}>
                {question.questionText}
              </Text>
            </FormLabel>
            <Select id={questionId} placeholder="Select option">
              {question.choices.map((choice) => {
                return formatMultipleChoiceItem(questionIndex, choice);
              })}
            </Select>
          </FormControl>
        );
      } else {
        return (
          <FormControl key={`fc${questionIndex}`} mt={questionIndex === 0 ? '0px' : '36px'} isRequired>
            <FormLabel htmlFor={questionId}>
              <Text as="span" color="black" textStyle={{ base: 'text-xs-regular', lg: 'text-md-regular' }}>
                {question.questionText}
              </Text>
            </FormLabel>
            <RadioGroup id={questionId}>
              <Stack spacing={6} direction={{ base: 'column', lg: 'row' }}>
                {question.choices.map((choice) => {
                  return formatMultipleChoiceRadio(questionIndex, choice);
                })}
              </Stack>
            </RadioGroup>
          </FormControl>
        );
      }
    } else if (question.answerType.toLowerCase() === 'decimal') {
      return (
        <FormControl key={`fc${questionIndex}`} mt={questionIndex === 0 ? '0px' : '36px'} isRequired>
          <FormLabel htmlFor={questionId}>
            <Text as="span" color="black" textStyle={{ base: 'text-xs-regular', lg: 'text-md-regular' }}>
              {question.questionText}
            </Text>
          </FormLabel>
          <NumberInput step={0.01} precision={2}>
            <NumberInputField
              mt={0}
              px={4}
              py="9px"
              id={questionId}
              color="brand.gray.400"
              textStyle={{ base: 'text-xs-regular', lg: 'text-md-regular' }}
              borderRadius="8px"
              border="1px solid var(--Gray-300, #D0D5DD)"
              background="#FEFEFF"
              onChange={(event) => updateQuestionnaireValue(questionIndex, event.currentTarget.value)}
            />
          </NumberInput>
        </FormControl>
      );
    }
    return (
      <FormControl key={`fc${questionIndex}`} mt={questionIndex === 0 ? '0px' : '36px'} isRequired>
        <FormLabel htmlFor={questionId}>
          <Text as="span" color="black" textStyle={{ base: 'text-xs-regular', lg: 'text-md-regular' }}>
            {question.questionText}
          </Text>
        </FormLabel>
        <Input
          mt={0}
          px={4}
          py="9px"
          id={questionId}
          color="brand.gray.400"
          textStyle={{ base: 'text-xs-regular', lg: 'text-md-regular' }}
          borderRadius="8px"
          border="1px solid var(--Gray-300, #D0D5DD)"
          background="#FEFEFF"
          onChange={(event) => updateQuestionnaireValue(questionIndex, event.currentTarget.value)}
        />
      </FormControl>
    );
  }

  // Can move this inline
  function formatSuitabilityQuestionnaire(questions: SuitabilityQuestion[]) {
    return (
      <>
        {questions.map((question, index) => {
          return formatSuitabilityQuestion(question, index);
        })}
      </>
    );
  }

  function resetQuestionnaireForm() {
    setHasError(false);
    setErrorMessage('');
    setSuitabilityMessages(null);
    reset();
  }

  if (isLoading)
    return (
      <Center>
        <VStack mt="5%" w={{ base: '311px', lg: '600px' }}>
          <BrandSpinner />
        </VStack>
      </Center>
    );

  if (isSubmitted && suitabilityMessages)
    return (
      <Center>
        <VStack mt="5%" w={{ base: '311px', lg: '600px' }} h="70vh">
          <Text color="gray.700" textStyle={{ base: 'text-sm-regular', lg: 'text-lg-regular' }}>
            Your suitability for investment failed for the following reasons:
          </Text>
          <ChakraList spacing={3} textStyle={{ base: 'text-xs-regular', lg: 'text-md-regular' }} color="brand.gray.700" mb={5}>
            {suitabilityMessages.map((message, index) => {
              return <ChakraListItem key={`message${index}`}>{message}</ChakraListItem>;
            })}
          </ChakraList>
          <Flex mt={8} alignItems="start">
            <Button
              bgColor="brand.blue.700"
              color="white"
              h="48px"
              w={{ base: '100%', lg: 'fit-content' }}
              textStyle={{ base: 'text-sm-regular', lg: 'text-md-regular' }}
              type="button"
              onClick={() => resetQuestionnaireForm()}
            >
              Try again!
            </Button>

            <Spacer />
          </Flex>
        </VStack>
      </Center>
    );

  // What to display if there is not a load error but no questions were returned
  if (!questionnaireForm || !questionnaireForm.suitabilityQuestions)
    return (
      <Center>
        <VStack mt="5%" w={{ base: '311px', lg: '600px' }}>
          <Text color="gray.400" textStyle={{ base: 'text-sm-regular', lg: 'text-md-regular' }}>
            We were unable to download the questionnaire for review. Please{' '}
            <ChakraLink as={ReactRouterLink} color="blue.700" textStyle={{ base: 'text-sm-semibold', lg: 'text-md-semibold' }} to={''}>
              Contact {environment.SupportName}
            </ChakraLink>
            .
          </Text>
        </VStack>
      </Center>
    );

  return (
    <Box mt={3}>
      <WindowAlert
        alertTitle="We are unable to process your request"
        alertMessage={errorMessage}
        alertFooter={`Please contact ${environment.SupportName}`}
        alertStatus="error"
        isOpen={hasError}
        onClose={() => setHasError(false)}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Center>
          <Text
            color="black"
            textStyle={{ base: 'text-sm-semibold', lg: 'text-xl-semibold' }}
            mt={{ base: '2px', lg: '2px' }}
            mb={{ base: '8px', lg: '12px' }}
          >
            Please answer the questions below.
          </Text>
        </Center>

        <Center>
          <VStack>
            <Box
              w={{ base: '350px', lg: '600px' }}
              border="1px"
              borderColor="gray.300"
              borderRadius="8px"
              overflowY="auto"
              pt={{ base: '16px', lg: '24px' }}
              pb={{ base: '16px', lg: '22px' }}
              ps={{ base: '16px', lg: '24px' }}
              pe={{ base: '16px', lg: '22px' }}
              sx={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#E0E0E0',
                  borderRadius: '24px',
                },
              }}
            >
              {questionnaireForm.suitabilityQuestions && formatSuitabilityQuestionnaire(questionnaireForm.suitabilityQuestions)}
            </Box>

            <Box color="brand.gray.500" textStyle={{ base: 'text-xs-regular', lg: 'text-sm-regular' }} w={{ base: '311px', lg: '600px' }}>
              <Center>You must complete the questionnaire to continue using our platform.</Center>
            </Box>

            <Flex w={{ base: '311px', lg: '600px' }} mt={4}>
              <Spacer />

              <Button
                border="2px"
                borderColor="brand.blue.700"
                bgColor={isSubmitting ? 'white' : 'brand.blue.700'}
                color={isSubmitting ? 'brand.blue.700' : 'white'}
                px="24px"
                py="12px"
                borderRadius="8px"
                isLoading={isSubmitting}
                isDisabled={isSubmitted || isSubmitting}
                loadingText="Submitting"
                textStyle="text-md-medium"
                type="submit"
                spinnerPlacement="end"
                opacity={isSubmitting ? '0.6' : '1.0'}
              >
                Submit
              </Button>
            </Flex>
          </VStack>
        </Center>
      </form>
    </Box>
  );
};
