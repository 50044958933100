import { Box, Center, Flex, Text, VStack } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { useState, useRef } from 'react';

import { environment } from '../../../../../environments/environment';
import { BrandSpinner, WindowAlert } from '../../../../components';
import { getUserSettingsProfile, IBaseResponse, UserSettingsProfileResponse } from '../../../../lib';

const defaultLoadErrorMessage = 'We are unable to download your profile.';

export const ProfileForm = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [profileData, setProfileData] = useState<UserSettingsProfileResponse | null>(null);

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  // Triggered more than once - use a reference variable to determine if component is mounted
  React.useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    // React advises to declare the async function directly inside useEffect
    async function loadProfile() {
      try {
        const response = await getUserSettingsProfile();
        if (response.successful === true && response.payload) {
          setProfileData(response.payload);
        } else if (!response.successful) {
          setErrorMessage(response.errorMessages.toString() ?? defaultLoadErrorMessage);
          setHasError(true);
        }
        setIsLoading(false);
      } catch (error) {
        setErrorMessage(
          (error as AxiosError<IBaseResponse<UserSettingsProfileResponse>>)?.response?.data?.errorMessages?.toString() ?? defaultLoadErrorMessage
        );
        setHasError(true);
        setIsLoading(false);
      }
    }

    loadProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading)
    return (
      <Center>
        <VStack mt="5%" w={{ base: '311px', lg: '600px' }}>
          <BrandSpinner />
        </VStack>
      </Center>
    );

  return (
    <Box width={{ base: '80vw', lg: 'calc(100vw - 350px)' }}>
      <WindowAlert
        alertTitle="We are unable to process your request"
        alertMessage={errorMessage}
        alertFooter={`If the error persists, please contact ${environment.SupportName}`}
        alertStatus="error"
        isOpen={hasError}
        onClose={() => setHasError(false)}
      />

      <VStack align="start" alignItems="stretch">
        <Box
          width={{ base: '80vw', lg: 'calc(100vw - 350px)' }}
          borderBottom="1px solid"
          borderBottomColor="gray.200"
          paddingBottom={4}
          marginBottom={5}
        >
          <Text color="gray.900" textStyle="text-lg-semibold">
            Personal Info
          </Text>
        </Box>

        <Flex
          direction={{ base: 'column', lg: 'row' }}
          alignItems="flex-start"
          alignContent="flex-start"
          alignSelf="stretch"
          width={{ base: '80vw', lg: 'calc(100vw - 350px)' }}
          borderBottom="1px solid"
          borderBottomColor="gray.200"
          paddingBottom={4}
          marginBottom={5}
          sx={{ gap: '16px 32px', lineHeight: '24px' }}
        >
          <Text color="gray.700" textStyle="text-sm-semibold" minW="200px" maxW="280px">
            Name
          </Text>
          <Box backgroundColor="gray.50" border="1px solid" borderColor="gray.300" borderRadius="8px" paddingX="10px" paddingY="14px">
            <Text color="gray.500" textStyle="text-sm-semibold" noOfLines={1} minW="216px" maxW="280px" me={6}>
              {profileData?.firstName ? profileData.firstName : ''}
            </Text>
          </Box>
          <Box backgroundColor="gray.50" border="1px solid" borderColor="gray.300" borderRadius="8px" paddingX="10px" paddingY="14px">
            <Text color="gray.500" textStyle="text-sm-semibold" noOfLines={1} minW="216px" maxW="280px" me={6}>
              {profileData?.lastName ? profileData.lastName : ''}
            </Text>
          </Box>
        </Flex>

        <Flex
          direction={{ base: 'column', lg: 'row' }}
          alignItems="flex-start"
          alignContent="flex-start"
          alignSelf="stretch"
          width={{ base: '80vw', lg: 'calc(100vw - 350px)' }}
          borderBottom="1px solid"
          borderBottomColor="gray.200"
          paddingBottom={4}
          marginBottom={5}
          sx={{ gap: '16px 32px', lineHeight: '24px' }}
        >
          <Text color="gray.700" textStyle="text-sm-semibold" minW="200px" maxW="280px">
            Email
          </Text>
          <Box backgroundColor="gray.50" border="1px solid" borderColor="gray.300" borderRadius="8px" paddingX="10px" paddingY="14px">
            <Text color="gray.500" textStyle="text-sm-semibold" noOfLines={1} minW={{ base: '70vw', lg: '512px' }} maxW="560px" me={6}>
              {profileData?.email ? profileData.email : ''}
            </Text>
          </Box>
        </Flex>
        {/*  */}
      </VStack>
    </Box>
  );
};
