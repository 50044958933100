import { Box, Center, Flex, Image, Link as ChakraLink, Show as ChakraShow, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import dynamicInvestSvg from '../../../../assets/dynamic-invest.svg';
import { RegisterForm } from '../components/RegisterForm';

export const Register = () => {
  const navigate = useNavigate();

  return (
    <Flex>
      <ChakraShow breakpoint="(min-width: 992px)">
        <Center w="550px" me={100}>
          <Image src={dynamicInvestSvg} w="550px" h="545px" />
        </Center>
      </ChakraShow>

      <Box w="500px">
        <Text textStyle={{ base: 'display-md-bold', lg: 'display-2xl-bold' }} color="brand.black">
          Get Started
        </Text>

        <Text color="brand.gray.500" my={5} textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
          You&apos;ll be able to explore Dynamic after you create an account.
        </Text>

        <RegisterForm onSuccess={() => navigate('/auth/confirmEmail')} />

        <Box color="brand.gray.400" mt="36px" textStyle="text-sm-regular">
          By providing your information and clicking Get Started, you agree to our
          <ChakraLink
            textDecoration="underline"
            mx="4px"
            onClick={() => window.open('https://cdn.dynamicinvest.dev/dynamic-documents/Dynamic%20Invest%20Terms%20and%20Conditions.pdf', '_blank')}
          >
            Terms of Use
          </ChakraLink>
          and
          <ChakraLink
            textDecoration="underline"
            ms="4px"
            onClick={() => window.open('https://devdynamicstatic.blob.core.windows.net/dynamic-documents/Privacy%20Policy.pdf', '_blank')}
          >
            Privacy Policy
          </ChakraLink>
          . You also elect to receive updates, newsletters and offers from Dynamic.
        </Box>
      </Box>
    </Flex>
  );
};
