import { Box, Button, Center, Flex, Link as ChakraLink, Spacer, Text, VStack } from '@chakra-ui/react';
import Axios, { AxiosError } from 'axios';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

import { environment } from '../../../../../environments/environment';
import { BaseFormProps, BrandSpinner, WindowAlert } from '../../../../components';
import { getPlatformAgreement, PlatformAgreementDto, useAcceptAgreement } from '../../../../lib/apis';
import { IBaseResponse } from '../../../../lib/interfaces';

interface IFormInput {
  agreementId: string;
  agreementName: string | null;
  agreementUrl: string;
}

function TextWithLineBreaks(textContent: string) {
  // Apply the style to a div that renders your text
  return <div style={{ whiteSpace: 'pre-line' }}>{textContent}</div>;
}

export const PlatformAgreementForm = ({ onSuccess }: BaseFormProps<IFormInput>) => {
  const { state } = useLocation();
  const acceptAgreementFn = useAcceptAgreement();
  const { handleSubmit, setValue } = useForm<IFormInput>();

  const [hasAgreement, setHasAgreement] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [documentText, setDocumentText] = useState('');

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  console.log('scrolledToBottom', scrolledToBottom);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (scrolledToBottom) return;

    try {
      // clientHeight is the container height
      // scrollHeight is the scroll "position" in the container
      // scrollTop is the top of the container
      const _bottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop <= e.currentTarget.clientHeight * 1.01;
      setScrolledToBottom(_bottom);
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const defaultLoadErrorMessage = 'We are unable to download the agreements and disclosures.';

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  // Triggered more than once - use a reference variable to determine if component is mounted
  React.useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    // React advises to declare the async function directly inside useEffect
    async function loadAgreement() {
      try {
        const response = await getPlatformAgreement();
        if (response.successful === true && response.payload !== null && response.payload.agreementUrl !== null) {
          setHasAgreement(true);
          setValue('agreementId', response.payload.agreementId);
          setValue('agreementName', response.payload.agreementName);
          setValue('agreementUrl', response.payload.agreementUrl);

          Axios.get(response.payload.agreementUrl, {
            withCredentials: false,
          })
            .then((res) => {
              const docData = res.data as string;
              setDocumentText(docData.trimEnd());
              if (res.data === null || res.data === undefined || res.data === '') {
                setErrorMessage(defaultLoadErrorMessage);
                setHasError(true);
              }
              setIsLoading(false);
            })
            .catch((err: Error | AxiosError) => {
              setErrorMessage(err.message);
              setHasError(true);
              setIsLoading(false);
            });
        } else {
          setErrorMessage(defaultLoadErrorMessage);
          setHasError(true);
          setIsLoading(false);
        }
      } catch (error) {
        setErrorMessage(
          (error as AxiosError<IBaseResponse<PlatformAgreementDto>>)?.response?.data?.errorMessages?.toString() || defaultLoadErrorMessage
        );
        setHasError(true);
        setIsLoading(false);
      }
    }

    loadAgreement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // User accepted agreement
  const onSubmit = async (values: IFormInput) => {
    setIsSubmitting(true);
    acceptAgreementFn.mutate(values, {
      onSuccess: (res) => {
        console.log('agreement -> success!', res);
        setIsSubmitted(true);
        setIsSubmitting(false);
        onSuccess(values);
        return;
      },
      onError: (error) => {
        console.log('agreement -> error', error);
        setErrorMessage(
          (error as AxiosError<IBaseResponse<boolean>>)?.response?.data?.errorMessages?.toString() ||
            `Failed to update your account. Please contact ${environment.SupportName}.`
        );
        setHasError(true);
        setIsSubmitting(false);
      },
    });
  };

  return (
    <>
      <WindowAlert
        alertTitle="We are unable to process your request"
        alertMessage={errorMessage}
        alertFooter={`Please contact ${environment.SupportName}`}
        alertStatus="error"
        isOpen={hasError}
        onClose={() => setHasError(false)}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Center>
          <Text
            color="black"
            textStyle={{ base: 'text-sm-semibold', lg: 'text-xl-semibold' }}
            mt={{ base: '16px', lg: '50px' }}
            mb={{ base: '32px', lg: '29px' }}
          >
            Dynamic Agreements and Disclosures
          </Text>
        </Center>

        {isLoading && (
          <Center>
            <VStack mt="5%" w="600px">
              <BrandSpinner />
            </VStack>
          </Center>
        )}

        {!isLoading && (!hasAgreement || isSubmitted) && (
          <Center>
            <VStack mt="5%" w="600px">
              <Text color="gray.400" textStyle="text-md-regular">
                Congratulations! You have completed this task.
              </Text>

              <Spacer />

              <ChakraLink as={ReactRouterLink} color="blue.700" textStyle="text-sm-semibold" to={'/home'} state={state}>
                Go to Home
              </ChakraLink>
            </VStack>
          </Center>
        )}

        {!isLoading && hasAgreement && hasError && (
          <Center>
            <VStack mt="5%" w="600px">
              <Text color="gray.400" textStyle="text-md-regular">
                We were unable to download the agreement and disclosure content for review. Please{' '}
                <ChakraLink as={ReactRouterLink} color="blue.700" textStyle="text-sm-semibold" to={''}>
                  Contact {environment.SupportName}
                </ChakraLink>
                .
              </Text>
            </VStack>
          </Center>
        )}

        {!isLoading && !isSubmitted && hasAgreement && !hasError && (
          <Center>
            <VStack>
              <Box
                w={{ base: '311px', lg: '600px' }}
                h={{ base: '511px', lg: '480px' }}
                border="1px"
                borderColor="gray.300"
                borderRadius="8px"
                overflowY="auto"
                py={{ base: '16px', lg: '24px' }}
                ps={{ base: '16px', lg: '24px' }}
                pe={{ base: '16px', lg: '22px' }}
                sx={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#E0E0E0',
                    borderRadius: '24px',
                  },
                }}
                onScroll={(event: React.UIEvent<HTMLDivElement>) => handleScroll(event)}
              >
                {TextWithLineBreaks(documentText)}
              </Box>

              <Box color="brand.gray.500" textStyle={{ base: 'text-xs-regular', lg: 'text-sm-regular' }} w={{ base: '311px', lg: '600px' }}>
                <Center>You must accept the Dynamic Agreement and Disclosures to continue using our platform.</Center>
              </Box>

              <Flex w={{ base: '311px', lg: '600px' }} mt={4}>
                <Spacer />

                <Button
                  border="2px"
                  borderColor="brand.blue.700"
                  bgColor={isSubmitting ? 'white' : 'brand.blue.700'}
                  color={isSubmitting ? 'brand.blue.700' : 'white'}
                  px="24px"
                  py="12px"
                  borderRadius="8px"
                  isLoading={isSubmitting}
                  isDisabled={!scrolledToBottom || isSubmitting || isSubmitted}
                  loadingText="Submitting"
                  textStyle="text-md-medium"
                  type="submit"
                  spinnerPlacement="end"
                  opacity={isSubmitting ? '0.6' : '1.0'}
                >
                  Accept
                </Button>
              </Flex>
            </VStack>
          </Center>
        )}
      </form>
    </>
  );
};
