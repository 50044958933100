/* eslint-disable react/jsx-key */
import { Button as ChakraButton, Card, CardBody, HStack, Image as ChakraImage, Show as ChakraShow, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';

import { StrategyDetailDto, StrategyDto } from '../../../../lib/types';

export type StrategyInfoProps = React.HTMLAttributes<HTMLElement> & {
  formIsSubmitting: boolean;
  onClickModal: (strategy: StrategyDto, strategyDetail: StrategyDetailDto | null) => void;
  onSelectStrategy: (strategy: StrategyDto) => void;
  strategy: StrategyDto;
  strategyDetail: StrategyDetailDto | null;
};

export const StrategyInfo = ({ formIsSubmitting, onClickModal, onSelectStrategy, strategy, strategyDetail, ...props }: StrategyInfoProps) => {
  return (
    <Card w="100%" px={6} py={6} mb={6} border="1px" borderRadius="8px" borderColor="gray.300" bgColor="white">
      <CardBody padding={0} w="100%">
        <VStack alignItems="stretch" spacing={0} w="100%">
          <HStack mb={6} padding={0}>
            <ChakraImage src={strategy.logoURL || ''} w={{ base: '40px', lg: '79px' }} h={{ base: '40px', lg: '79px' }}></ChakraImage>
            <Text as="span" color="black" textStyle={{ base: 'text-xl-medium', lg: 'text-xl-semibold' }} ms="4px">
              {strategy.name}
            </Text>
          </HStack>

          <Text color="gray.500" mb={6} textStyle={{ base: 'text-xs-regular', lg: 'text-sm-regular' }}>
            {strategy.shortDescription}
          </Text>

          <HStack gap={{ base: 4, md: 4 }}>
            <ChakraButton
              bgColor="white"
              border="1px"
              borderColor="blue.700"
              borderRadius="8px"
              isLoading={formIsSubmitting}
              paddingX={4}
              paddingY="14px"
              onClick={() => onClickModal(strategy, strategyDetail)}
              type="button"
            >
              <Text color="blue.700" textStyle={{ base: 'text-sm-regular', lg: 'text-md-regular' }}>
                <ChakraShow breakpoint="(max-width: 991px)">Information</ChakraShow>
                <ChakraShow breakpoint="(min-width: 992px)">Strategy Information</ChakraShow>
              </Text>
            </ChakraButton>

            <ChakraButton
              bgColor="blue.700"
              border="1px"
              borderColor="blue.700"
              borderRadius="8px"
              isLoading={formIsSubmitting}
              paddingX={4}
              paddingY="14px"
              onClick={() => onSelectStrategy(strategy)}
              type="button"
            >
              <Text color="white" textStyle={{ base: 'text-sm-regular', lg: 'text-md-regular' }}>
                <ChakraShow breakpoint="(max-width: 991px)">Select</ChakraShow>
                <ChakraShow breakpoint="(min-width: 992px)">Select Strategy</ChakraShow>
              </Text>
            </ChakraButton>
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  );
};
