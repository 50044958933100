import { Box, Button, Center, Flex, Link as ChakraLink, Show as ChakraShow, Spacer, Text, VStack } from '@chakra-ui/react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent, StripePaymentElementOptions } from '@stripe/stripe-js';
import React, { useState } from 'react';

import { environment } from '../../../../../environments/environment';
import { StrategyDetailDto, UserStrategySubscriptionDto } from '../../../../lib/types';
import { UserStrategySubscriptionInfo } from '../Elements/UserStrategySubscriptionInfo';

interface IConfigureBillingFormInputs {
  paymentElementOptions: StripePaymentElementOptions;
  strategyDetails: StrategyDetailDto;
  subscriptionInfo: UserStrategySubscriptionDto;
}

export const ConfigureBillingForm = ({ paymentElementOptions, strategyDetails, subscriptionInfo }: IConfigureBillingFormInputs) => {
  const stripe = useStripe();
  const elements = useElements();

  // handling page state
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setMessage('');

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsSubmitting(true);

    // target URL
    // include strategy & billing set-up
    const targetUrl = `${window.location.protocol}//${window.location.host}/onboard/confirmBilling?userStrategyId=${subscriptionInfo.userStrategyId}&strategyId=${strategyDetails.id}`;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // this is the confirmation page
        return_url: `${targetUrl}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      console.log('configure billing form: error from stripe', error.message);
      setMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    setIsSubmitting(false);
  };

  return (
    <Flex mt={{ base: '0px', lg: '24px' }} w="100%" flexDirection={{ base: 'column', md: 'row' }} gap={1}>
      {/* */}

      {/* Strategy Info Section */}
      <Flex w={{ base: '100%', lg: '40%' }} mt={{ base: '0px', md: '24px' }}>
        {/* https://v2.chakra-ui.com/docs/styled-system/responsive-styles */}
        <ChakraShow breakpoint="(min-width: 992px)">
          <Spacer />
        </ChakraShow>

        <Box me={{ base: '0px', md: '38px' }} w={{ base: '100%', md: 'fit-content' }}>
          <UserStrategySubscriptionInfo strategyDetails={strategyDetails} />
        </Box>
      </Flex>

      {/* Billing widget */}
      <Center mt={{ base: '16px', md: '24px' }}>
        <form id="payment-form" onSubmit={handleSubmit}>
          <VStack gap={8}>
            <Box minW={{ base: '300px', md: '500px' }}>
              <PaymentElement
                id="payment-element"
                options={paymentElementOptions}
                onChange={(event: StripePaymentElementChangeEvent) => {
                  setMessage('');
                }}
              />
            </Box>
            <Button
              border="2px"
              borderColor="brand.blue.700"
              bgColor={isSubmitting ? 'white' : 'brand.blue.700'}
              color={isSubmitting ? 'brand.blue.700' : 'white'}
              h="48px"
              w="100%"
              isLoading={isSubmitting}
              isDisabled={isSubmitting || !stripe || !elements}
              loadingText="Submitting"
              textStyle="text-md-medium"
              type="submit"
              spinnerPlacement="end"
              opacity={isSubmitting ? '0.6' : '1.0'}
            >
              <span id="button-text">{isSubmitting ? <div className="spinner" id="spinner"></div> : 'Subscribe'}</span>
            </Button>

            {message && (
              <Text as="span" textStyle={{ base: 'text-xs-medium', lg: 'text-sm-medium' }} color="#FF5A5A" my={0}>
                {message}
              </Text>
            )}

            <Text maxW={{ base: '300px', md: '500px' }} color="gray.500" textStyle="text-sm-regular">
              By confirming your subscription, you allow {environment.StripeEnvironmentName} to charge you for future payments in accordance with
              their terms. You can always cancel your subscription.
            </Text>

            <Center maxW={{ base: '300px', md: '500px' }}>
              <Text as="span" color="gray.400" textStyle="text-xs-regular" sx={{ lineHeight: '35px' }}>
                Powered by
              </Text>

              <ChakraLink
                ms={1}
                color="gray.600"
                onClick={() => window.open(environment.StripePoweredByUrl, '_blank')}
                textDecoration="none"
                sx={{ lineHeight: '35px' }}
              >
                <Text as="span" textStyle="text-xs-bold" color="gray.600" sx={{ lineHeight: '35px' }}>
                  stripe
                </Text>
              </ChakraLink>

              <Text as="span" color="gray.400" textStyle="text-xl-regular" ms={4} sx={{ lineHeight: '35px' }}>
                |
              </Text>

              <ChakraLink
                ms={4}
                color="gray.400"
                onClick={() => window.open(environment.StripeTermsUrl, '_blank')}
                textDecoration="none"
                sx={{ lineHeight: '35px' }}
              >
                <Text as="span" textStyle="text-xs-regular" color="gray.400" sx={{ lineHeight: '35px' }}>
                  Terms
                </Text>
              </ChakraLink>

              <ChakraLink
                ms={4}
                color="gray.400"
                onClick={() => window.open(environment.StripePrivacyUrl, '_blank')}
                textDecoration="none"
                sx={{ lineHeight: '35px' }}
              >
                <Text as="span" textStyle="text-xs-regular" color="gray.400" sx={{ lineHeight: '35px' }}>
                  Privacy
                </Text>
              </ChakraLink>
            </Center>
          </VStack>
        </form>
      </Center>
    </Flex>
  );
};
