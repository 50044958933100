import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { isUserInfoDto } from '../../../lib/types';
import { determineLandingPage } from '../../../lib/utils/user-events';
import { PlatformAgreementForm } from '../components/Form/PlatformAgreementForm';

export const PlatformAgreement = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const onSubmit = async () => {
    if (state?.userData !== null && isUserInfoDto(state.userData)) {
      state.userData.isCurrentAgreementAccepted = true;
    }
    const targetUrl = determineLandingPage(state?.userData);
    navigate(`${targetUrl}`, { state: { userData: state?.userData }, replace: true });
  };

  return (
    <Flex>
      <Box w="100%">
        <PlatformAgreementForm onSuccess={() => onSubmit()} />
      </Box>
    </Flex>
  );
};
