import { Box, Button as ChakraButton, Center, Flex, Image, Show as ChakraShow, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dynamicInvestSvg from '../../../../assets/dynamic-invest.svg';
import { ButtonIcon } from '../../../components';
import { ForgotPasswordForm } from '../components/ForgotPasswordForm';

// Text for prompt state
const PromptText = () => {
  return (
    <Text color="brand.gray.500" my={5} textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
      No worries, we&apos;ll send you reset instructions
    </Text>
  );
};

// Text for confirmation state
const ConfirmationText = () => {
  return (
    <Box>
      <Text color="brand.gray.500" mt={5} mb={2} textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
        If the email address you entered is associated with an account in our system, we will send a link to reset your password.
      </Text>

      <Text color="brand.gray.500" mb={5} textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
        Please check your email inbox (and the spam/junk folder) for further instructions. The password reset link will expire in 24 hours.
      </Text>
    </Box>
  );
};

export const ForgotPassword = () => {
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);

  return (
    <Flex>
      <ChakraShow breakpoint="(min-width: 992px)">
        <Center w="550px" me={100}>
          <Image src={dynamicInvestSvg} w="550px" h="545px" />
        </Center>
      </ChakraShow>

      <Box maxW="500" mt={{ base: '0rem', lg: '4rem' }}>
        <Text textStyle={{ base: 'display-md-bold', lg: 'display-xl-bold' }} color="brand.black">
          Forgot Password?
        </Text>

        {submitted && (
          <>
            <ConfirmationText />

            <Box mt={8}>
              <ChakraButton
                bgColor="brand.blue.700"
                color="white"
                h="48px"
                mt={8}
                pe="2px"
                w={{ base: '100%', lg: 'fit-content' }}
                rightIcon={<ButtonIcon />}
                textStyle="text-md-medium"
                onClick={() => navigate('/auth/login')}
              >
                Back to Login
              </ChakraButton>
            </Box>
          </>
        )}

        {!submitted && (
          <>
            <PromptText />

            <ForgotPasswordForm onSuccess={() => setSubmitted(true)} />
          </>
        )}
      </Box>
    </Flex>
  );
};
