import { axios } from '../hooks/axios';
import { IBaseResponse } from '../interfaces/IBaseResponse';
import { UserSettingsBillingResponse, UserSettingsProfileResponse } from '../types';

export const getUserSettingsProfile = async (): Promise<IBaseResponse<UserSettingsProfileResponse | null>> => {
  return axios.get('/user/settings/profile');
};

export const getUserSettingsBilling = async (): Promise<IBaseResponse<UserSettingsBillingResponse | null>> => {
  return axios.get('/user/settings/billing');
};
