import { UIComponentType } from '../interfaces';
import { DefaultOnboardingEventDto, OnboardingEventDto, UserInfoDto } from '../types';

import { UIComponentSettings } from './ui-components';

export type OnboardNextStepDto = OnboardingEventDto & {
  targetUrl: string;
  continuationText: string;
  isDisabled: boolean;
  disabledText: string;
};

export function determineLandingPage(data: UserInfoDto | null) {
  if (data === null || data === undefined) return '/auth/login';
  else if (!data.isCurrentAgreementAccepted) return '/msc/platformAgreement';
  else if (!data.isSuitabilityVerified) return '/msc/questionnaire';
  else if (!data.isStrategySelected) return '/onboard/selectStrategy';
  else return '/home';
}

export function getNextStep(userState: UserInfoDto, events: OnboardingEventDto[]): OnboardNextStepDto {
  let targetUrl = '/home';
  let continuationText = '';
  let result: OnboardNextStepDto = {
    continuationText: continuationText,
    targetUrl: targetUrl,
    isDisabled: true,
    disabledText: '',
    ...DefaultOnboardingEventDto,
  };

  const nextStep = events.sort((a, b) => a.sequence - b.sequence).find((e) => e.isComplete === false);
  if (nextStep === undefined) return result;

  const uiComponent = nextStep.uiComponent || 'home';

  try {
    targetUrl = UIComponentSettings[uiComponent.toLowerCase()].targetUrl;
    continuationText = UIComponentSettings[uiComponent.toLowerCase()].continuationText;
  } catch (error) {
    console.log('Unable to retrieve configuration for UI Component', uiComponent);
  }

  result = {
    continuationText: continuationText,
    targetUrl: targetUrl,
    isDisabled: nextStep.uiComponent === UIComponentType.billing && !userState.isKYCVerified,
    disabledText:
      nextStep.uiComponent === UIComponentType.billing && !userState.isKYCVerified
        ? 'We are currently verifying your identity. Please wait for this process to complete before continuing with the onboarding process. We will notify you once the verification is finished.'
        : '',
    ...nextStep,
  };

  return result;
}

export function getSequentialOnboardingStep(userState: UserInfoDto, uiComponent: string, events: OnboardingEventDto[]) {
  let targetUrl = '/home';
  let continuationText = '';
  let result: OnboardNextStepDto = {
    continuationText: continuationText,
    targetUrl: targetUrl,
    isDisabled: false,
    disabledText: '',
    ...DefaultOnboardingEventDto,
  };

  const nextStep = events
    .sort((a, b) => a.sequence - b.sequence)
    .find((e) => e.isComplete === false && e.uiComponent?.toLowerCase() !== uiComponent.toLowerCase());
  if (nextStep === undefined) return result;

  const nextUiComponent = nextStep.uiComponent || 'home';

  try {
    targetUrl = UIComponentSettings[nextUiComponent.toLowerCase()].targetUrl;
    continuationText = UIComponentSettings[nextUiComponent.toLowerCase()].continuationText;
  } catch (error) {
    console.log('Unable to retrieve configuration for UI Component', nextUiComponent);
  }

  result = {
    continuationText: continuationText,
    targetUrl: targetUrl,
    isDisabled: nextStep.uiComponent === UIComponentType.billing && !userState.isKYCVerified,
    disabledText:
      nextStep.uiComponent === UIComponentType.billing && !userState.isKYCVerified
        ? 'We are currently verifying your identity. Please wait for this process to complete before continuing with the onboarding process. We will notify you once the verification is finished.'
        : '',
    ...nextStep,
  };
  return result;
}
