import { Box, Hide, HStack, Image as ChakraImage, Show as ChakraShow, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import { StrategyDetailDto } from '../../../../lib';

interface IUserStrategySubscriptionInfo {
  strategyDetails: StrategyDetailDto;
}

export const UserStrategySubscriptionInfo = ({ strategyDetails }: IUserStrategySubscriptionInfo) => {
  return (
    <Box mb={{ base: '12px', md: '0px' }} width="100%">
      <VStack alignItems={{ base: 'center', md: 'stretch' }} width="100%" gap={2}>
        <Text mt={{ base: '0px', lg: '8px' }} mb={1} color="gray.500" textStyle={{ base: 'text-xs-medium', lg: 'text-sm-semibold' }}>
          Subscribe to {strategyDetails.name}
        </Text>

        <HStack padding={0}>
          <Hide above="sm">
            <ChakraImage src={'https://cdn.dynamicinvest.dev/logos/Ark_Invest_logo.svg'} h="40px" w="111px" me={6} />
          </Hide>

          <Text as="span" textStyle={{ base: 'display-xs-semibold', lg: 'display-md-semibold' }} color="black">
            {strategyDetails === null || strategyDetails.pricing === null || strategyDetails.pricing.pricePerUnit === null
              ? 'N/A'
              : strategyDetails!.pricing!.pricePerUnit.toLocaleString('en-US', {
                  style: 'currency',
                  currency: strategyDetails!.pricing!.currency,
                })}
          </Text>

          <VStack gap={0}>
            <Text as="span" textStyle={{ base: 'text-xs-regular', lg: 'text-sm-regular' }} color="gray.500" my={0}>
              {strategyDetails === null || strategyDetails.pricing === null ? 'N/A' : strategyDetails!.pricing!.interval}
            </Text>
          </VStack>
        </HStack>

        <Text my={1} color="gray.500" textStyle={{ base: 'text-xs-regular', lg: 'text-sm-regular' }}>
          Portfolio management of {strategyDetails.name} strategy
        </Text>

        {/* https://v2.chakra-ui.com/docs/styled-system/responsive-styles */}
        <ChakraShow breakpoint="(min-width: 992px)">
          <Box ms="30px" my="64px" padding={0}>
            <ChakraImage src={strategyDetails.logoURL || ''} w="220px" h="120px"></ChakraImage>
          </Box>
        </ChakraShow>
      </VStack>
    </Box>
  );
};
