import { VStack } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { environment } from '../../../../environments/environment';
import { CallToAction, CallToActionDto, NextUp, NextUpDto, NextUpStepType, WindowAlert } from '../../../components';
import { IBaseResponse } from '../../../lib';
import { getStrategies, getUserDashboard, getUserSettingsBilling } from '../../../lib/apis';
import { useUser } from '../../../lib/providers/auth';
import { EmptyDashboardData, EmptyStrategies, StrategiesDto, UserDashboardDto, UserStrategyDashboardDto } from '../../../lib/types';
import { determineLandingPage, getNextStep } from '../../../lib/utils/user-events';
import { useOnboardStore } from '../../onboard/stores/onboard';
import { HowToTransferFundsModal } from '../components/Modal/HowToTransferFundsModal';
import { Portfolio } from '../components/Portfolio';

const defaultLoadErrorMessage = 'We are access to download your account at this time.';

export const Home = () => {
  const navigate = useNavigate();

  // cache user data for 10 seconds, should handle react rendering
  const { data: userData, isSuccess: userIsSuccess, isLoading: userIsLoading, error: userError } = useUser({ staleTime: 10000 });

  const [componentIsLoading, setComponentIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(EmptyDashboardData);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [strategyData, setStrategyData] = useState(EmptyStrategies);

  // handle Modal states
  const [isConnectToCoinbaseModalOpen, setIsConnectToCoinbaseModalOpen] = useState(false);
  const [isFundingModalOpen, setIsFundingModalOpen] = useState(false);
  const [isKycModalOpen, setIsKycModalOpen] = useState(false);

  // Onboarding state
  const setOnboard = useOnboardStore((state) => state.setOnboard);
  const clearOnboard = useOnboardStore((state) => state.clearOnboard);

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  // Triggered more than once - use a reference variable to determine if component is mounted
  React.useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    clearOnboard();

    // React advises to declare the async function directly inside useEffect
    async function getDashboardData() {
      // TODO: Need to cache this
      try {
        const response = await getStrategies();
        if (response.successful && response.payload) setStrategyData(response.payload);
        else if (!response.successful) {
          setErrorMessage(response.errorMessages.toString() ?? defaultLoadErrorMessage);
          setHasError(true);
        }
      } catch (error) {
        setErrorMessage((error as AxiosError<IBaseResponse<StrategiesDto>>)?.response?.data?.errorMessages?.toString() ?? defaultLoadErrorMessage);
        setHasError(true);
        console.log('error', error);
      }

      try {
        const response = await getUserDashboard();
        if (response.successful && response.payload) setDashboardData(response.payload);
        else if (!response.successful) {
          setErrorMessage(response.errorMessages.toString() ?? defaultLoadErrorMessage);
          setHasError(true);
        }
      } catch (error) {
        setErrorMessage((error as AxiosError<IBaseResponse<UserDashboardDto>>)?.response?.data?.errorMessages?.toString() ?? defaultLoadErrorMessage);
        setHasError(true);
        console.log('error', error);
      }

      setComponentIsLoading(false);
    }
    //
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // open billing site in new window
  const onBillingClick = async () => {
    const response = await getUserSettingsBilling();
    if (response.successful === true && response.payload) {
      window.open(response.payload?.sessionURL, '_blank');
    }
  };

  // Set-up Onboarding state for NextUp when clicked
  function nextUpOnClick(portfolio: UserStrategyDashboardDto) {
    const strategy = strategyData.strategies?.find((s) => s.id === portfolio.strategyId) ?? null;
    const nextStepData = getNextStep(userData!, portfolio.userStrategyOnboardingStatus.onboardingEvents);
    setOnboard({
      strategy: {
        id: portfolio.strategyId,
        name: portfolio.strategyName,
        managerName: null,
        shortDescription: portfolio.strategyDescription,
        logoURL: strategy?.logoURL || '',
      },
      onboardEvents: portfolio.userStrategyOnboardingStatus.onboardingEvents.sort((a, b) => a.sequence - b.sequence),
      userStrategyId: portfolio.id,
    });
    navigate(`${nextStepData.targetUrl}`, { state: { userData: userData }, replace: true });
  }

  //*******************************/
  //**** Functions to format data */
  //*******************************/
  // User account-level Call to Actions:
  // - Reverify KYC TBD
  //
  // Portfolio Call to Actions:
  // - Fund Account (!IsFunded)
  // - Exchange needs re-connected (!isExchangeConnected)
  // - Billing needs re-connected (!IsBillingSubscriptionActive)
  function formatBillingCallToAction(portfolios: UserStrategyDashboardDto[]) {
    // Apply the style to a div that renders your text
    return portfolios
      .filter((p) => p.id !== '' && p.isOnboardingComplete && !p.isBillingSubscriptionActive)
      .map((portfolio) => {
        const strategy = strategyData.strategies?.find((s) => s.id === portfolio.strategyId) ?? null;
        const billingCallToActionData: CallToActionDto = {
          strategyLogo: strategy?.logoURL,
          strategyName: strategy?.name,
          title: 'Reconnect billing for your portfolio',
          // eslint-disable-next-line quotes
          message: `We'll walk you through each step of the process.`,
          buttonText: 'Set-up billing',
        };
        return <CallToAction key={portfolio.id} data={billingCallToActionData} displayButton={true} onClick={() => onBillingClick()}></CallToAction>;
      });
  }
  function formatConnectExchangeCallToAction(portfolios: UserStrategyDashboardDto[]) {
    // Apply the style to a div that renders your text
    return portfolios
      .filter((p) => p.id !== '' && p.isOnboardingComplete && !p.isExchangeConnected)
      .map((portfolio) => {
        const strategy = strategyData.strategies?.find((s) => s.id === portfolio.strategyId) ?? null;
        const exchangeCallToActionData: CallToActionDto = {
          strategyLogo: strategy?.logoURL,
          strategyName: strategy?.name,
          title: 'Update the Coinbase exchange settings for your portfolio',
          // eslint-disable-next-line quotes
          message: `We'll walk you through each step of the process.`,
          buttonText: 'Connect to Coinbase',
        };
        return (
          <CallToAction
            key={portfolio.id}
            data={exchangeCallToActionData}
            displayButton={true}
            onClick={() => setIsConnectToCoinbaseModalOpen(true)} // navigate To Connect to Coinbase once set-up
          ></CallToAction>
        );
      });
  }
  function formatFundingCallToAction(portfolios: UserStrategyDashboardDto[]) {
    // Apply the style to a div that renders your text
    return portfolios
      .filter((p) => p.id !== '' && p.isOnboardingComplete && !p.isFunded)
      .map((portfolio) => {
        const strategy = strategyData.strategies?.find((s) => s.id === portfolio.strategyId) ?? null;
        const fundingCallToActionData: CallToActionDto = {
          strategyLogo: strategy?.logoURL,
          strategyName: strategy?.name,
          title: 'Add funds to your Coinbase portfolio',
          // eslint-disable-next-line quotes
          message: `We'll walk you through each step of the process.`,
          buttonText: 'How to Transfer Funds',
        };
        return (
          <CallToAction
            key={portfolio.id}
            data={fundingCallToActionData}
            displayButton={true}
            onClick={() => setIsFundingModalOpen(true)}
          ></CallToAction>
        );
      });
  }

  // Next Up (e.g., Connect to Coinbase)
  function formatNextUp(portfolios: UserStrategyDashboardDto[]) {
    // Apply the style to a div that renders your text
    return portfolios
      .filter((p) => p.id !== '' && !p.isOnboardingComplete)
      .map((portfolio) => {
        const strategy = strategyData.strategies?.find((s) => s.id === portfolio.strategyId) ?? null;
        const sortedEvents = portfolio.userStrategyOnboardingStatus.onboardingEvents.sort((a, b) => a.sequence - b.sequence);
        const nextStepData = getNextStep(userData!, sortedEvents);
        const nextStepsData: NextUpStepType[] = sortedEvents.map((step) => {
          const stepData: NextUpStepType = {
            name: step.name || '',
            isCurrent: step.name === nextStepData.name,
            isComplete: step.isComplete,
          };
          return stepData;
        });
        const nextUpData: NextUpDto = {
          strategyLogoUrl: strategy?.logoURL || '',
          strategyName: strategy?.name || '',
          stepTitle: nextStepData.name || '',
          stepMessage: nextStepData.description || '',
          stepUrl: nextStepData.targetUrl,
          isStepDisabled: nextStepData.isDisabled,
          disabledMessage: nextStepData.disabledText,
          // eslint-disable-next-line quotes
          buttonText: 'Continue',
          nextSteps: nextStepsData,
        };
        return <NextUp key={portfolio.id} data={nextUpData} displaySteps={true} onClick={() => nextUpOnClick(portfolio)}></NextUp>;
      });
  }

  function formatPortfolio(portfolios: UserStrategyDashboardDto[]) {
    return portfolios
      .filter((p) => p.id !== '' && p.isOnboardingComplete)
      .map((portfolio) => {
        const strategy = strategyData.strategies?.find((s) => s.id === portfolio.strategyId) ?? null;
        return <Portfolio key={portfolio.id} userStrategy={portfolio} strategy={strategy}></Portfolio>;
      });
  }

  //***********************************************************************/
  //******************** Display logic ************************************/
  if (userError as AxiosError) {
    setErrorMessage((userError as AxiosError).message ?? defaultLoadErrorMessage);
    setHasError(true);
  }

  // TODO: some kind of suspense loading here
  if (hasError)
    return (
      <>
        <WindowAlert
          alertTitle="We are unable to process your request"
          alertMessage={errorMessage}
          alertFooter={`If the error persists, please contact ${environment.SupportName}`}
          alertStatus="error"
          isOpen={hasError}
          onClose={() => setHasError(false)}
        />
        {formatPortfolio([])}
      </>
    );

  // TODO: some kind of suspense loading here
  if (userIsLoading || componentIsLoading) return <div>Loading...</div>;

  if (userIsSuccess) {
    // Check if we need to redirect to a different page
    const landingPage = determineLandingPage(userData);
    if (landingPage !== '/home') {
      navigate(`${landingPage}`, { state: { userData: userData }, replace: true });
      return;
    }

    return (
      <>
        <HowToTransferFundsModal
          isOpen={isFundingModalOpen}
          onClose={() => setIsFundingModalOpen(false)}
          title="Transfer Assets into your Coinbase Portfolio"
        />

        <WindowAlert
          alertTitle="Connect to Coinbase"
          alertMessage="Not implemented (yet)."
          alertStatus="info"
          isOpen={isConnectToCoinbaseModalOpen}
          onClose={() => setIsConnectToCoinbaseModalOpen(false)}
        />

        <WindowAlert
          alertTitle="Verify Identity"
          alertMessage="Not implemented (yet)."
          alertStatus="info"
          isOpen={isKycModalOpen}
          onClose={() => setIsKycModalOpen(false)}
        />

        <VStack gap={4} alignItems="start">
          {/* Call to Actions */}
          {dashboardData.userStrategies && formatBillingCallToAction(dashboardData.userStrategies)}
          {dashboardData.userStrategies && formatConnectExchangeCallToAction(dashboardData.userStrategies)}
          {dashboardData.userStrategies && formatFundingCallToAction(dashboardData.userStrategies)}

          {/* Next Up */}
          {dashboardData.userStrategies && formatNextUp(dashboardData.userStrategies)}

          {/* Portfolio Summary */}
          {dashboardData.userStrategies && formatPortfolio(dashboardData.userStrategies)}
        </VStack>
      </>
    );
  }

  return <div>Unable to load dashboard. Please contact {environment.SupportName}.</div>;
};
