import { Box, Button, Center, Flex, FormControl, FormErrorMessage, Input, Link as ChakraLink, useMediaQuery } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as ReactRouterLink } from 'react-router-dom';
import Turnstile from 'react-turnstile';

import { environment } from '../../../../environments/environment';
import { BaseFormProps, ButtonIcon, WindowAlert } from '../../../components';
import { forgotPassword } from '../../../lib/apis/user';
import { IBaseResponse } from '../../../lib/interfaces';
import { ForgotPasswordRequestDto } from '../../../lib/types';
import { EmailRegex } from '../validation/EmailRules';

interface IFormInput {
  email: string;
}

export const ForgotPasswordForm = ({ onSuccess }: BaseFormProps<IFormInput>) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IFormInput>();

  const defaultErrorMessage = 'Failed to send change password email.';

  // https://v2.chakra-ui.com/docs/styled-system/responsive-styles
  const [isLargerThanTablet] = useMediaQuery('(min-width: 62em)');

  const [cftToken, setCftToken] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const requireCaptcha = environment.TargetEnvironment !== 'local';

  const onSubmit = async (values: IFormInput) => {
    setIsSubmitting(true);
    try {
      const data: ForgotPasswordRequestDto = {
        emailAddress: values.email,
        captchaToken: cftToken,
      };
      const response = await forgotPassword(data);

      if (response.successful) {
        setSuccess(true);
        onSuccess(values);
        setIsSubmitting(false);
      } else {
        setErrorMessage(response.errorMessages.length < 1 ? defaultErrorMessage : response.errorMessages.toString());
        setError(true);
      }
      //
    } catch (error) {
      setErrorMessage((error as AxiosError<IBaseResponse<boolean>>)?.response?.data?.errorMessages?.toString() || defaultErrorMessage);
      setError(true);
      setIsSubmitting(false);
    }

    // Reset token after submission
    setCftToken('');
  };

  return (
    <>
      <WindowAlert
        alertTitle="We are unable to process your request"
        alertMessage={errorMessage}
        alertFooter={`Please contact ${environment.SupportName}`}
        alertStatus="error"
        isOpen={error}
        onClose={() => setError(false)}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.email} mt={{ base: '1rem', lg: '2.5rem' }}>
          <Input
            id="email"
            color="brand.gray.400"
            placeholder="Enter your email"
            ps="2"
            textStyle="text-md-regular"
            type="text"
            variant="flushed"
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: EmailRegex,
                message: 'Invalid email address',
              },
            })}
          />
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>

        {requireCaptcha && (
          <Flex mt={{ base: '1rem', lg: '2rem' }}>
            <Turnstile sitekey={`${environment.CftSiteKey}`} onVerify={(token) => setCftToken(token)} />
          </Flex>
        )}

        <Box mt={{ base: '2.5rem', lg: '2rem' }}>
          <Button
            border="2px"
            borderColor="brand.blue.700"
            bgColor={isSubmitting ? 'white' : 'brand.blue.700'}
            color={isSubmitting ? 'brand.blue.700' : 'white'}
            h="48px"
            pe={isSubmitting ? '20px' : '2px'}
            w={{ base: '100%', lg: 'fit-content' }}
            rightIcon={<ButtonIcon />}
            isLoading={isSubmitting}
            isDisabled={isSubmitting || success}
            loadingText="Submitting"
            textStyle="text-md-medium"
            type="submit"
            spinnerPlacement="end"
            opacity={isSubmitting ? '0.6' : '1.0'}
          >
            Reset password
          </Button>
        </Box>

        {isLargerThanTablet && (
          <Box mt={8}>
            <ChakraLink as={ReactRouterLink} color="brand.blue.700" textStyle="text-md-medium" to={'/auth/login'}>
              Back to Login
            </ChakraLink>
          </Box>
        )}

        {!isLargerThanTablet && (
          <Center mt={8}>
            <ChakraLink as={ReactRouterLink} color="brand.blue.700" textStyle="text-sm-regular" to={'/auth/login'}>
              Back to Login
            </ChakraLink>
          </Center>
        )}
      </form>
    </>
  );
};
