import { Circle, Show as ChakraShow } from '@chakra-ui/react';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const ButtonIcon = () => {
  return (
    // https://v2.chakra-ui.com/docs/styled-system/responsive-styles
    <ChakraShow breakpoint="(min-width: 992px)">
      <Circle bg="white" borderRadius="20px" size="38px" ms="8px">
        <FontAwesomeIcon icon={faArrowRight} color="black" />
      </Circle>
    </ChakraShow>
  );
};
