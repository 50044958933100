import { Box, Center, Flex, Show as ChakraShow, Spacer, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BrandSpinner, OnboardProgressStatus } from '../../../components';
import { UIComponentType } from '../../../lib/interfaces';
import { VerifyIdentityForm } from '../components/Form/VerifyIdentityForm';
import { getOnboardUserStrategyId, getUrlForNextOnboardEvent, useOnboardStore } from '../stores/onboard';

export const VerifyIdentity = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [confirmed, setConfirmed] = useState(false);

  // TODO: handle mutating state better
  const onboardStore = useOnboardStore();

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    const userStrategyId = getOnboardUserStrategyId();

    // React advises to declare the async function directly inside useEffect
    async function loadOnboardingDetails() {
      // check to see if we lost on boarding state
      if (userStrategyId !== null) {
        const success = await onboardStore.reloadOnboard(userStrategyId);
        if (!success) navigate('/home', { state: { userData: state?.userData }, replace: true });
        onboardStore.onboard = onboardStore.getOnboard(); // TODO
      }
    }

    if (onboardStore.onboard.userStrategyId === null || onboardStore.onboard.userStrategyId !== userStrategyId) {
      loadOnboardingDetails();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // For now, ass-u-me that the KYC collection process is complete and the user advances
  const onConfirmed = async () => {
    setConfirmed(true);

    const onboardEvent = onboardStore.onboard.onboardEvents?.find((c) => c.name?.toLowerCase() === UIComponentType.kyc);
    if (onboardEvent !== null && onboardEvent !== undefined) {
      onboardEvent.completedDate = new Date();
      onboardEvent.isComplete = true;
    }

    navigate(`${await getUrlForNextOnboardEvent(onboardStore.onboard.onboardEvents)}`, { state: { userData: state?.userData }, replace: true });
  };

  return (
    <>
      {/* https://v2.chakra-ui.com/docs/styled-system/responsive-styles */}
      <ChakraShow breakpoint="(min-width: 992px)">
        <Box position="absolute" top="0" right="0" padding="10px 22px">
          <OnboardProgressStatus events={onboardStore.onboard.onboardEvents} eventCount={null} completedEventCount={null} />
        </Box>
      </ChakraShow>
      <ChakraShow breakpoint="(max-width: 991px)">
        <Flex w="90vw" mb={6} mt={0}>
          <Spacer />
          <OnboardProgressStatus events={onboardStore.onboard.onboardEvents} eventCount={null} completedEventCount={null} />
        </Flex>
      </ChakraShow>

      <Text mb={{ base: '0px', lg: '20px' }} textStyle={{ base: 'text-md-semibold', lg: 'display-md-semibold' }} color="brand.black">
        Verify your identity
      </Text>

      <Flex flexDirection="column" justifyContent="start" w="100%">
        <Box w="100%">
          {confirmed && (
            <Center>
              <VStack mt="5%" w="600px">
                <BrandSpinner />
              </VStack>
            </Center>
          )}

          {!confirmed && (
            <VerifyIdentityForm
              onSuccess={() => {
                onConfirmed();
              }}
            />
          )}
        </Box>
      </Flex>
    </>
  );
};
