import React from 'react';
import { useRoutes } from 'react-router-dom';

import { AnonymousLayout } from '../components';
import { Login } from '../features/auth/routes/Login';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  // ick - checking session storage for active session but at least we won't increment the api rate count
  // todo: move session check to a function
  const hasSessionToken = sessionStorage.getItem('accessToken') !== null;

  const commonRoutes = [
    {
      path: '/',
      element: (
        <AnonymousLayout>
          <Login />
        </AnonymousLayout>
      ),
    },
  ];

  const fallbackRoute = hasSessionToken
    ? []
    : [
        {
          path: '*',
          element: (
            <AnonymousLayout>
              <Login />
            </AnonymousLayout>
          ),
        },
      ];

  const routes = hasSessionToken ? protectedRoutes : [];

  const element = useRoutes([...publicRoutes, ...routes, ...commonRoutes, ...fallbackRoute]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{element}</>;
};
