import { Box, Flex, VStack, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { Header, SideNav, TopNav } from '..';

export const MainLayout = () => {
  // https://v2.chakra-ui.com/docs/styled-system/responsive-styles
  const [isLargerThanTablet] = useMediaQuery('(min-width: 62em)');

  if (isLargerThanTablet)
    return (
      <Flex h="100vh">
        <SideNav />

        <Box flex={1} pt={6} px={6}>
          {/* Welcome header goes here then content should be vertically stacked in this box */}
          <Header />

          {/* This is the content for the route target */}
          <Outlet />
        </Box>
      </Flex>
    );

  return (
    <VStack w="100vw">
      <TopNav />

      <Box flex={1} pt={2} px={6}>
        {/* Welcome header goes here then content should be vertically stacked in this box */}
        <Header />

        {/* This is the content for the route target */}
        <Outlet />
      </Box>
    </VStack>
  );
};
