import {
  Box,
  Center,
  Flex,
  HStack,
  Image as ChakraImage,
  Link as ChakraLink,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import coinbaseAdvancedLogo from '../../../../../assets/icon-CoinbaseAdvanced.svg';
import contentImage from '../../../../../assets/screenshot-ConnectCoinbase.png';
import { environment } from '../../../../../environments/environment';

type HowToTransferFundsModalProps = React.HTMLAttributes<HTMLElement> & {
  isOpen: boolean;
  onClose: () => void;
  title: string;
};

export const HowToTransferFundsModal = ({ isOpen, onClose, title, ...props }: HowToTransferFundsModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        maxH="80vh"
        maxW="80vw"
        px={10}
        py={10}
        sx={{
          boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.10)',
        }}
      >
        <ModalCloseButton />
        <ModalBody
          borderRadius="8px"
          w="100%"
          sx={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#E0E0E0',
              borderRadius: '24px',
            },
          }}
        >
          <VStack alignItems="stretch" w="100%">
            <Center mb="28px">
              <ChakraImage src={'https://cdn.dynamicinvest.dev/logos/Ark_Invest_logo.svg'} h="30px" w="144.5px" />
            </Center>

            <Center>
              <Text mb={2} w="619px">
                <HStack gap={0} lineHeight="36px">
                  <Text textStyle="display-xs-semibold" color="black">
                    {title}
                  </Text>
                  <VStack alignItems="end" gap={0}>
                    <ChakraImage src={coinbaseAdvancedLogo} h="27.4px" w="92px" ms={3} />
                    <Text
                      as="span"
                      color="#5B5B5B"
                      fontSize="8.8px"
                      fontWeight={500}
                      fontStyle="normal"
                      fontFamily={"'Plus Jakarta Sans', sans-serif"}
                      lineHeight="normal"
                      me="0.1rem"
                      mt="-0.25rem"
                    >
                      Advanced
                    </Text>
                  </VStack>
                </HStack>
              </Text>
            </Center>

            <VStack>
              <Flex alignItems="stretch" px={4} py={5} w="640px">
                <Box w="100%" bgColor="brand.success.100" border="1px solid" borderColor="brand.success.700" borderRadius={8} px={3} py={3}>
                  <VStack alignItems="start">
                    <Text textStyle="text-lg-bold" color="black">
                      Please Note:
                    </Text>

                    <Text textStyle="text-md-semibold" color="black">
                      These assets will be allocated to the strategy that you subscribed to.
                    </Text>

                    <HStack gap={0}>
                      <Text as="span" color="black" textStyle="text-md-semibold" textDecoration="none" me={2}>
                        View
                      </Text>

                      <ChakraLink color="blue.700" onClick={() => window.open(environment.TransferAssetsUrl, '_blank')} textDecoration="none">
                        <Text as="span" textStyle="text-md-semibold" textDecoration="underline" color="blue.700" me={2}>
                          full guide
                        </Text>

                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} fontSize="12px" color="blue.700" />
                      </ChakraLink>
                    </HStack>
                  </VStack>
                </Box>
              </Flex>

              <VStack alignItems="start" gap={0} mt={1}>
                <Text textStyle="text-md-regular" color="black" mb={5}>
                  Watch the video below to get instruction on how to transfer your assets
                </Text>

                <ChakraImage src={contentImage} w="618px" h="369px" borderRadius="16px"></ChakraImage>
              </VStack>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
