import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

import { OnboardingEventDto } from '../../../lib';

// eslint-disable-next-line import/no-unresolved
import './OnboardProgressStatus.css';

export interface IOnboardProgressStatusInfo {
  events: OnboardingEventDto[] | null;
  eventCount: number | null;
  completedEventCount: number | null;
}

export const OnboardProgressStatus = ({ events, eventCount, completedEventCount }: IOnboardProgressStatusInfo) => {
  /* Calculate the % complete */
  const stepCount = events !== null ? events.length : (eventCount ?? 1);
  const completeCount = events !== null ? events.filter((e) => e.isComplete === true).length : (completedEventCount ?? 0);
  const percentComplete = stepCount < 1 ? 0 : (completeCount / stepCount) * 100;

  return (
    <Box
      width="100%"
      display="inline-flex"
      padding="8px 36px 7px 12px"
      alignItems="center"
      gap={3}
      borderRadius="8px"
      border="2px solid"
      borderColor="brand.borderLight"
    >
      <HStack w="fit-content" alignItems="start">
        <Box w={{ base: '36px', lg: '48px' }} h={{ base: '36px', lg: '48px' }}>
          <div className="OnboardProgressStatus">
            <CircularProgressbar value={completeCount} maxValue={stepCount} text={`${percentComplete}%`} />
          </div>
          {/*  */}
        </Box>

        <VStack gap={0} alignItems="start">
          <Text color="black" textStyle={{ base: 'text-sm-regular', md: 'text-md-regular' }} lineHeight="22px">
            {stepCount === completeCount ? 'Onboarding complete' : 'Complete onboarding'}
          </Text>

          <Text color="gray.500" textStyle={{ base: 'text-xs-regular', md: 'text-sm-regular' }} lineHeight="16px" overflow="hidden">
            {completeCount}/{stepCount} Completed
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
};
