import { Box, Center, Flex, Show as ChakraShow, Spacer, Text, VStack } from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance } from '@stripe/stripe-js';
import { AxiosError } from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { environment } from '../../../../environments/environment';
import { BrandSpinner, OnboardProgressStatus, WindowAlert } from '../../../components';
import {
  getSequentialOnboardingStep,
  getStrategyDetail,
  getUserSettingsBilling,
  getUserStrategy,
  IBaseResponse,
  StrategyDetailDto,
  StripeContext,
  UIComponentType,
  UserSettingsBillingResponse,
  UserStrategyDto,
} from '../../../lib';
import { useUser } from '../../../lib/providers/auth';
import { ConfirmBillingForm } from '../components/Form/ConfirmBillingForm';
import { useOnboardStore } from '../stores/onboard';

const defaultLoadingErrorMessage = 'Unable to retrieve account information.';
const defaultViewDetailsErrorMessage = 'Unable to access billing account. Please try again later.';

//---------------------------------------
// vvv-- Options for billing widget --vvv
const appearance: Appearance = {
  theme: 'stripe',
};
// Enable the skeleton loader UI for optimal loading.
const loader = 'auto';
// ^^^-- Options for billing widget --^^^
//---------------------------------------

export const ConfirmBilling = () => {
  const stripePromise = useContext(StripeContext);
  const navigate = useNavigate();

  // cache user data for 1 minute
  const { data: userData, isSuccess: userIsSuccess, isLoading: userIsLoading, error: userError } = useUser({ staleTime: 60000 });

  const [continueButtonText, setContinueButtonText] = useState('Continue');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertFooter, setAlertFooter] = useState(`Please contact ${environment.SupportName}`);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState('');

  const [strategyDetails, setStrategyDetails] = useState<StrategyDetailDto | null>(null);

  // Onboarding state
  const setOnboard = useOnboardStore((state) => state.setOnboard);
  const onboardState = useOnboardStore((state) => state.onboard);

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (isMountedRef.current === true || userIsLoading) return;
    isMountedRef.current = true;

    // Pick-up "state" from parameters - ugly -> need to reload EVERYTHING
    const searchParameters = new URLSearchParams(window.location.search);
    const strategyId = searchParameters.get('strategyId');
    const userStrategyId = searchParameters.get('userStrategyId');

    const _paymentIntentclientSecret = searchParameters.get('payment_intent_client_secret');
    if (_paymentIntentclientSecret) setPaymentIntentClientSecret(_paymentIntentclientSecret);

    // Check for errors loading user
    if (userError || (!userIsSuccess && !userIsLoading)) {
      setErrorMessage(defaultLoadingErrorMessage);
      setError(true);
      setIsLoading(false);
    }

    // React advises to declare the async function directly inside useEffect
    async function loadOnboardingDetails() {
      // frickin' mess because we lose state with the transfer from stripe
      if (strategyId && userStrategyId && userData)
        try {
          getStrategyDetail(strategyId)
            .then(async (response) => {
              if (response.successful && response.payload && response.payload.pricing) {
                setStrategyDetails(response.payload);

                getUserStrategy(userStrategyId)
                  .then(async (userStrategyResponse) => {
                    // recreate the onboard state
                    if (userStrategyResponse.successful && userStrategyResponse.payload) {
                      setOnboard({
                        strategy: {
                          id: userStrategyResponse.payload.strategyId,
                          name: userStrategyResponse.payload.strategyName,
                          managerName: response.payload.managerName,
                          shortDescription: userStrategyResponse.payload.strategyDescription,
                          logoURL: response.payload.logoURL || '',
                        },
                        onboardEvents: userStrategyResponse.payload.userStrategyOnboardingStatus.onboardingEvents,
                        userStrategyId: userStrategyId,
                      });

                      // Determine confirmation text based on next steps
                      if (userStrategyResponse.payload.userStrategyOnboardingStatus.onboardingEvents !== null) {
                        const nextStep = getSequentialOnboardingStep(
                          userData,
                          UIComponentType.billing,
                          userStrategyResponse.payload.userStrategyOnboardingStatus.onboardingEvents
                        );
                        if (nextStep.name) setContinueButtonText(nextStep.name);
                      }
                    }
                  })
                  .catch((error) => {
                    setErrorMessage(
                      (error as AxiosError<IBaseResponse<UserStrategyDto>>)?.response?.data?.errorMessages?.toString() || defaultLoadingErrorMessage
                    );
                    setError(true);
                    setIsLoading(false);
                  });
              } else {
                setErrorMessage(
                  (response.errorMessages?.length || 0) < 1
                    ? 'Unable to retrieve pricing information for selected strategy.'
                    : response.errorMessages.toString()
                );
                setError(true);
              }
              setIsLoading(false);
            })
            .catch((error) => {
              setErrorMessage(
                (error as AxiosError<IBaseResponse<StrategyDetailDto>>)?.response?.data?.errorMessages?.toString() || defaultLoadingErrorMessage
              );
              setError(true);
              setIsLoading(false);
            });

          //
        } catch (error) {
          setErrorMessage(
            (error as AxiosError<IBaseResponse<StrategyDetailDto>>)?.response?.data?.errorMessages?.toString() || defaultLoadingErrorMessage
          );
          setError(true);
          setIsLoading(false);
        }
    }

    loadOnboardingDetails();
  }, [setOnboard, userData, userError, userIsLoading, userIsSuccess]);

  // open billing site in new window
  const onBillingDetailsClick = async () => {
    setAlertFooter('');
    try {
      const response = await getUserSettingsBilling();
      if (response.successful === true && response.payload) {
        window.open(response.payload?.sessionURL, '_blank');
      } else {
        setErrorMessage(response.errorMessages.length < 1 ? defaultViewDetailsErrorMessage : response.errorMessages.toString());
        setError(true); // triggers window alert
      }
    } catch (error) {
      setErrorMessage(
        (error as AxiosError<IBaseResponse<UserSettingsBillingResponse>>)?.response?.data?.errorMessages?.toString() || defaultViewDetailsErrorMessage
      );
      setError(true);
    }
  };

  // User reviewed the billing results... which may not be updated in the backend so continue on to next step
  const onContinue = async () => {
    const onboardSteps = onboardState.onboardEvents ? onboardState.onboardEvents : [];
    const nextStep = getSequentialOnboardingStep(userData!, UIComponentType.billing, onboardSteps);
    navigate(`${nextStep.targetUrl}`, { state: { userData: userData }, replace: true });
  };

  if (isLoading)
    return (
      <Center>
        <VStack mt="5%" w={{ base: '90vw', lg: '600px' }}>
          <BrandSpinner />
        </VStack>
      </Center>
    );

  return (
    <Box w={{ base: '90vw', lg: 'calc(100vw - 350px)' }}>
      <WindowAlert
        alertTitle="We are unable to process your request"
        alertMessage={errorMessage}
        alertFooter={alertFooter}
        alertStatus="error"
        isOpen={error}
        onClose={() => setError(false)}
      />

      {/* TODO: When caching strategies, include event count */}
      <ChakraShow breakpoint="(min-width: 992px)">
        <Box position="absolute" top="0" right="0" padding="10px 28px">
          <OnboardProgressStatus events={onboardState.onboardEvents} eventCount={null} completedEventCount={null} />
        </Box>
      </ChakraShow>
      <ChakraShow breakpoint="(max-width: 991px)">
        <Flex w="90vw" mb={6} mt={0}>
          <Spacer />
          <OnboardProgressStatus events={onboardState.onboardEvents} eventCount={null} completedEventCount={null} />
        </Flex>
      </ChakraShow>

      <Flex flexDirection="column" justifyContent="start" w="100%">
        <ChakraShow breakpoint="(min-width: 992px)">
          <Text mb={{ base: '20px', lg: '0rem' }} textStyle={{ base: 'text-md-semibold', lg: 'display-sm-semibold' }} color="black">
            Billing Confirmation
          </Text>
        </ChakraShow>

        <Box w="100%">
          {strategyDetails && stripePromise && (
            <Box mt="2%" w={{ base: '80vw', lg: '100%' }}>
              <Elements stripe={stripePromise} options={{ clientSecret: paymentIntentClientSecret, appearance, loader }}>
                <ConfirmBillingForm
                  continueButtonText={continueButtonText}
                  onContinue={async () => onContinue()}
                  onViewDetailsClick={async () => onBillingDetailsClick()}
                  paymentIntentClientSecret={paymentIntentClientSecret}
                  strategyDetails={strategyDetails}
                />
              </Elements>
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
};
