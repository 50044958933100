import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { environment } from '../../../../environments/environment';
import { SettingsNav, WindowAlert } from '../../../components';
import { useUser } from '../../../lib/providers/auth';
import { ProfileForm } from '../components/Form/ProfileForm';

const defaultLoadErrorMessage = 'We are unable to access your account at this time.';

export const Profile = () => {
  const navigate = useNavigate();

  // cache user data for 10 seconds, should handle react rendering
  const { data: userData, isSuccess: userIsSuccess, isLoading: userIsLoading, error: userError } = useUser({ staleTime: 10000 });

  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);

  //***********************************************************************/
  //******************** Display logic ************************************/
  if (userIsLoading) return <div>Loading...</div>;

  if (userIsSuccess) {
    // Verify platform agreement has been accepted
    if (!userData?.isCurrentAgreementAccepted) {
      navigate('/msc/platformAgreement', { state: { userData: userData } });
      return;
    }

    // Verify suitability for investment
    if (!userData?.isSuitabilityVerified) {
      navigate('/msc/questionnaire', { state: { userData: userData } });
      return;
    }
  }

  if (userIsSuccess || userError) {
    if (userError as AxiosError) {
      setErrorMessage((userError as AxiosError).message ?? defaultLoadErrorMessage);
      setHasError(true);
    }

    return (
      <>
        <WindowAlert
          alertTitle="We are unable to process your request"
          alertMessage={errorMessage}
          alertFooter={`If the error persists, please contact ${environment.SupportName}`}
          alertStatus="error"
          isOpen={hasError}
          onClose={() => setHasError(false)}
        />

        <SettingsNav />

        <ProfileForm />
      </>
    );
  }

  return <div>Unable to load user profile. Please contact {environment.SupportName}.</div>;
};
