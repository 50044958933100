import { AxiosError } from 'axios';
import { create } from 'zustand'; // Does not save state when browser refreshed

import { getStrategy, getUserStrategy, IBaseResponse } from '../../../lib';
import { OnboardingEventDto, StrategyDto, UserStrategyDto } from '../../../lib/types';

export type Onboard = {
  strategy: StrategyDto | null;
  onboardEvents: OnboardingEventDto[] | null;
  userStrategyId: string | null;
};

type OnboardStore = {
  onboard: Onboard;
  setOnboard: (state: Onboard) => void;
  clearOnboard: () => void;
  getOnboard: () => Onboard;
  reloadOnboard: (userStrategyId: string) => Promise<boolean>;
};

const defaultState: Onboard = {
  strategy: null,
  onboardEvents: null,
  userStrategyId: null,
};

export const useOnboardStore = create<OnboardStore>((set, get) => ({
  onboard: defaultState,
  getOnboard: () => {
    return get().onboard; // TODO: Nust be a better way
  },
  setOnboard: (onboard: Onboard) => {
    set({ onboard });
    sessionStorage.setItem('usid', onboard?.userStrategyId ?? '');
    console.log('state in store hook: ', onboard);
  },
  clearOnboard: () => {
    set({ onboard: defaultState });
    sessionStorage.setItem('usid', '');
  },
  reloadOnboard: async (userStrategyId: string): Promise<boolean> => {
    const result = await reloadOnboardState(userStrategyId);
    if (result.success && result.onboard) {
      set({ onboard: result.onboard });
    }
    return result.success;
  },
}));

// Determine URL for provided step
export const getUrlForOnboardEvent = async (stepIndex: number | null, onboardEvents: OnboardingEventDto[] | null): Promise<string> => {
  let targetUrl = '/home';

  if (onboardEvents === null || onboardEvents.length === 0) return targetUrl;

  if (stepIndex === null) {
    const incompleteEvents = onboardEvents.filter((e) => e.isComplete === false);
    if (incompleteEvents === null) return targetUrl;
    stepIndex = onboardEvents.indexOf(incompleteEvents[0]);
  } else {
    stepIndex--;
  }

  if (stepIndex > -1 && stepIndex < onboardEvents.length) {
    const uiComponent = onboardEvents[stepIndex].uiComponent?.toLowerCase() || '';
    switch (uiComponent) {
      case 'strategy selection':
        targetUrl = '/onboard/selectStrategy';
        break;
      case 'kyc':
        targetUrl = '/onboard/verifyIdentity';
        break;
      case 'connect coinbase':
        targetUrl = '/onboard/connectCoinbase';
        break;
      case 'billing':
        targetUrl = '/onboard/configureBilling';
        break;
    }
  }

  return targetUrl;
};

export const getUrlForNextOnboardEvent = async (onboardEvents: OnboardingEventDto[] | null): Promise<string> => {
  return getUrlForOnboardEvent(null, onboardEvents);
};

export const getOnboardUserStrategyId = (): string | null => {
  const userStrategyId = sessionStorage.getItem('usid');
  return userStrategyId === '' ? null : userStrategyId;
};

interface IReloadOnboardState {
  onboard: Onboard | null;
  responseMessages: string[];
  success: boolean;
}

const reloadOnboardState = async (userStrategyId: string): Promise<IReloadOnboardState> => {
  if (userStrategyId.trim() === '') {
    return {
      onboard: null,
      responseMessages: ['User strategy not provided'],
      success: false,
    };
  }

  const result: IReloadOnboardState = {
    onboard: null,
    responseMessages: [],
    success: false,
  };

  try {
    // Retrieve user strategy
    const userStrategyResponse = await getUserStrategy(userStrategyId);

    if (!userStrategyResponse.successful) {
      const errorMessages =
        userStrategyResponse.errorMessages.length === 0
          ? ['Unable to retrieve user strategy information', 'Error not provided.']
          : userStrategyResponse.errorMessages;
      console.log(errorMessages.toString());
      errorMessages.forEach((msg) => result.responseMessages.push(msg));
      return result;
    }

    // Retrieve strategy
    const strategyResponse = await getStrategy(userStrategyResponse.payload.strategyId);
    if (!strategyResponse.successful) {
      const errorMessages =
        strategyResponse.errorMessages.length === 0
          ? ['Unable to retrieve strategy information', 'Error not provided.']
          : strategyResponse.errorMessages;
      console.log(errorMessages.toString());
      errorMessages.forEach((msg) => result.responseMessages.push(msg));
      return result;
    }

    // Populate the onboard
    result.onboard = {
      strategy: {
        id: userStrategyResponse.payload.strategyId,
        name: userStrategyResponse.payload.strategyName,
        managerName: strategyResponse.payload.managerName,
        shortDescription: strategyResponse.payload.shortDescription,
        logoURL: strategyResponse.payload.logoURL || '',
      },
      onboardEvents: userStrategyResponse.payload.userStrategyOnboardingStatus.onboardingEvents,
      userStrategyId: userStrategyId,
    };
    result.success = true;

    // Catch axios exceptions
  } catch (error) {
    const errorMessages = (error as AxiosError<IBaseResponse<UserStrategyDto>>)?.response?.data?.errorMessages ||
      (error as AxiosError<IBaseResponse<StrategyDto>>)?.response?.data?.errorMessages || ['Unable to retrieve user strategy information'];
    console.log(errorMessages.toString());
    errorMessages.forEach((msg) => result.responseMessages.push(msg));
  }

  return result;
};
