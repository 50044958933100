// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  is_local: false,
  production: false,
  DynamicInvestUrl: 'https://api.dynamicinvest.dev',
  CftSiteKey: '0x4AAAAAAAfLNPRA3rZj4KPd',
  TargetEnvironment: 'development',
  // Dynamic Support
  SupportName: 'Dynamic Support',
  SupportEmail: 'client-services@dynamicinvest.io',
  SupportHours: '9AM - 6PM EST, M-F',
  // Learning Center
  LearningCenterUrl: 'https://help.dynamicinvest.dev',
  LearningCenterName: 'Help Center',
  ConnectToCoinbaseVideoUrl: 'https://ebadevv2staticassets.blob.core.windows.net/cms-media/assets/1_Whyis_Blockchain_Sucha_Big_Deal_711a4f0add.mp4',
  // Links to Content
  SecurityBestPracticesUrl: 'https://docs.cdp.coinbase.com/advanced-trade/docs/security#storing-credentials-securely',
  CoinbaseAdvancedPortfolioUrl: 'https://www.coinbase.com/advanced-portfolio',
  CoinbaseAdvancedApiSettingsUrl: 'https://www.coinbase.com/settings/api',
  CoinbaseAdvancedSignUpUrl: 'https://www.coinbase.com/signup?from=advanced',
  TransferAssetsUrl: 'https://www.google.com/',
  // Stripe connectivity
  StripePublishKey: 'pk_test_51Q8bBhIocXSOQiDwehXTy3yPKmQKXeVu9beMHm2e4Rku4UeZdOGrzcO8gLSWT66W3DajmGIbCoxuOcKXKRswMotX00PvsYxgGg',
  StripePaymentDetails: 'https://dashboard.stripe.com/payments/',
  StripeEnvironmentName: 'Dynamic Invest - Sandbox',
  StripePoweredByUrl: 'https://stripe.com/',
  StripeTermsUrl: 'https://stripe.com/legal/consumer',
  StripePrivacyUrl: 'https://stripe.com/privacy',
};
