import { axios } from '../hooks/axios';
import { IBaseResponse } from '../interfaces/IBaseResponse';
import { UserStrategiesDto, UserStrategyDto, UserStrategySubscriptionRequest, UserStrategySubscriptionDto } from '../types';

export type UserStrategyOnboardRequest = {
  strategyId: string;
};

export const getUserStrategies = async (): Promise<IBaseResponse<UserStrategiesDto>> => {
  return axios.get('/user-strategies');
};

export const getUserStrategy = async (id: string): Promise<IBaseResponse<UserStrategyDto>> => {
  return axios.get(`/user-strategy/${id}`);
};

export const addUserStrategy = async (data: UserStrategyOnboardRequest): Promise<IBaseResponse<UserStrategyDto>> => {
  return axios.post('/user-strategy', data);
};

export const getUserStrategySubscription = async (data: UserStrategySubscriptionRequest): Promise<IBaseResponse<UserStrategySubscriptionDto>> => {
  return axios.post('/user-strategy/subscription', data);
};
