import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  List as ChakraList,
  ListIcon as ChakraListIcon,
  ListItem as ChakraListItem,
  Spacer,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import Turnstile from 'react-turnstile';

import { environment } from '../../../../environments/environment';
import { ButtonIcon, WindowAlert } from '../../../components';
import { IBaseResponse } from '../../../lib/interfaces';
import { useResetPassword } from '../../../lib/providers/auth';
import { ResetPasswordCredentialsDto } from '../../../lib/types';
import { DigitRegex, LowercaseRegEx, MinLengthRegEx, SpecialCharactersRegEx, UppercaseRegex, ValidPasswordRegex } from '../validation/PasswordRules';

const CheckListIcon = (props: any) => {
  return <CheckCircleIcon {...props} />;
};

interface IFormInput {
  password: string;
  confirmPassword: string;
  hasDigit: boolean;
  hasMinLength: boolean;
  hasLowercase: boolean;
  hasSpecialChar: boolean;
  hasUppercase: boolean;
}

interface IResetPasswordFormProps {
  onSuccess: () => any;
}

export const ResetPasswordForm = ({ onSuccess }: IResetPasswordFormProps) => {
  const resetPasswordFn = useResetPassword();

  const [resetPasswordError, setResetPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [cftToken, setCftToken] = useState('');
  const requireCaptcha = !(environment.TargetEnvironment === 'local');

  const [searchParams] = useSearchParams();
  const nonce = searchParams.get('nonce') || '';
  const canSubmit = true && nonce;

  const {
    handleSubmit,
    register,
    watch,
    reset,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IFormInput>();

  const watchName = watch('password', '');

  React.useEffect(() => {
    setValue('hasDigit', DigitRegex.test(watchName));
    setValue('hasMinLength', MinLengthRegEx.test(watchName));
    setValue('hasLowercase', LowercaseRegEx.test(watchName));
    setValue('hasSpecialChar', SpecialCharactersRegEx.test(watchName));
    setValue('hasUppercase', UppercaseRegex.test(watchName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchName]);

  const onSubmit = async (values: IFormInput) => {
    setIsSubmitting(true);
    const resetData: ResetPasswordCredentialsDto = {
      password: values.password,
      nonce: nonce,
      captchaToken: cftToken,
    };

    resetPasswordFn.mutate(resetData, {
      onSuccess: () => {
        onSuccess();
        setIsSubmitting(false);
      },
      onError: (error) => {
        reset();
        // Determine whether an array of strings or objects is returned
        const errMsgs = (error as AxiosError<IBaseResponse<boolean>>)?.response?.data?.errorMessages || [{}];
        const isStringArray =
          Array.isArray(errMsgs) &&
          errMsgs.length > 0 &&
          errMsgs.every((value) => {
            return typeof value === 'string';
          });
        setErrorMessage(isStringArray ? errMsgs.toString() : 'Failed to reset password.');
        setResetPasswordError(true);
        setIsSubmitting(false);
      },
    });

    // Reset token after submission
    setCftToken('');
  };

  return (
    <>
      <WindowAlert
        alertTitle="We are unable to update your credentials"
        alertMessage={errorMessage}
        alertStatus="error"
        alertFooter={`Please contact ${environment.SupportName}`}
        isOpen={resetPasswordError}
        onClose={() => setResetPasswordError(false)}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.password} mb={5}>
          <Input
            id="password"
            color="brand.gray.400"
            placeholder="Password"
            ps="2"
            textStyle="text-md-regular"
            type="password"
            autoComplete="off"
            variant="flushed"
            {...register('password', {
              required: 'Password is required',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters',
              },
              pattern: {
                value: ValidPasswordRegex,
                message:
                  'Password must contain one digit from 1 to 9, one lowercase letter, one uppercase letter, one special character, no space, and it must be 8 characters long',
              },
            })}
          />
          <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.confirmPassword} mb={5}>
          <Input
            id="confirmPassword"
            color="brand.gray.400"
            placeholder="Confirm Password"
            ps="2"
            textStyle="text-md-regular"
            type="password"
            autoComplete="off"
            variant="flushed"
            {...register('confirmPassword', {
              required: 'Please confirm your password',
              validate: (value) => value === watch('password') || 'Passwords do not match',
            })}
          />
          <FormErrorMessage>{errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
        </FormControl>

        <ChakraList spacing={3} textStyle="text-xs-regular" color="brand.gray.500" mb={5}>
          <ChakraListItem>
            <ChakraListIcon as={CheckListIcon} w={4} h={4} color={watch('hasMinLength') ? 'brand.success.300' : 'brand.gray.300'} />
            Must be at least 8 characters
          </ChakraListItem>
          <ChakraListItem>
            <ChakraListIcon as={CheckListIcon} w={4} h={4} color={watch('hasUppercase') ? 'brand.success.300' : 'brand.gray.300'} />
            Must contain at least one uppercase character
          </ChakraListItem>
          <ChakraListItem>
            <ChakraListIcon as={CheckListIcon} w={4} h={4} color={watch('hasLowercase') ? 'brand.success.300' : 'brand.gray.300'} />
            Must contain at least one lowercase character
          </ChakraListItem>
          <ChakraListItem>
            <ChakraListIcon as={CheckListIcon} w={4} h={4} color={watch('hasDigit') ? 'brand.success.300' : 'brand.gray.300'} />
            Must contain at least one digit
          </ChakraListItem>
          <ChakraListItem>
            <ChakraListIcon as={CheckListIcon} w={4} h={4} color={watch('hasSpecialChar') ? 'brand.success.300' : 'brand.gray.300'} />
            Must contain at least one special character
          </ChakraListItem>
        </ChakraList>

        {requireCaptcha && (
          <Flex mt={8}>
            <Turnstile sitekey={`${environment.CftSiteKey}`} onVerify={(token) => setCftToken(token)} />
          </Flex>
        )}

        <Flex mt={8}>
          <Button
            border="2px"
            borderColor="brand.blue.700"
            bgColor={isSubmitting ? 'white' : 'brand.blue.700'}
            color={isSubmitting ? 'brand.blue.700' : 'white'}
            h="48px"
            pe={isSubmitting ? '20px' : '2px'}
            w={{ base: '100%', lg: 'fit-content' }}
            rightIcon={<ButtonIcon />}
            isLoading={isSubmitting}
            isDisabled={isSubmitting || isSubmitSuccessful || (requireCaptcha && !cftToken) || !canSubmit}
            loadingText="Submitting"
            textStyle="text-md-medium"
            type="submit"
            spinnerPlacement="end"
            opacity={isSubmitting ? '0.6' : '1.0'}
          >
            Change Password
          </Button>

          <Spacer />
        </Flex>
      </form>
    </>
  );
};
