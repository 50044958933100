import { Box, Center, Flex, Show as ChakraShow, Spacer, Text, VStack } from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, StripePaymentElementOptions } from '@stripe/stripe-js';
import { AxiosError } from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { environment } from '../../../../environments/environment';
import { BrandSpinner, OnboardProgressStatus, WindowAlert } from '../../../components';
import { StripeContext } from '../../../lib';
import { getStrategyDetail, getUserStrategySubscription, userState } from '../../../lib/apis';
import { IBaseResponse } from '../../../lib/interfaces';
import { StrategyDetailDto, UserStrategySubscriptionDto } from '../../../lib/types';
import { ConfigureBillingForm } from '../components/Form/ConfigureBillingForm';
import { getOnboardUserStrategyId, useOnboardStore } from '../stores/onboard';

const defaultLoadingErrorMessage = 'Unable to retrieve billing option(s) for selected strategy.';

//---------------------------------------
// vvv-- Options for billing widget --vvv
const appearance: Appearance = {
  theme: 'stripe',
  variables: {
    borderRadius: '8px',
    colorDanger: '#FF5A5A',
    fontFamily: "'Manrope', sans-serif",
    gridRowSpacing: '16px',
    spacingUnit: '4px',
  },
};
const paymentElementOptions: StripePaymentElementOptions = {
  layout: 'accordion',
};
// Enable the skeleton loader UI for optimal loading.
// always: Display skeleton loader UI while waiting for Elements to be fully loaded, after they are mounted.
// Default is 'auto' (Stripe determines if a loader UI should be shown).
const loader = 'always';
// ^^^-- Options for billing widget --^^^
//---------------------------------------

export const ConfigureBilling = () => {
  const stripePromise = useContext(StripeContext);
  const { state } = useLocation();
  const navigate = useNavigate();

  // TODO: handle mutating state better
  const onboardStore = useOnboardStore();

  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [strategyDetails, setStrategyDetails] = useState<StrategyDetailDto | null>(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState<UserStrategySubscriptionDto | null>(null);

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    const userStrategyId = getOnboardUserStrategyId();

    // React advises to declare the async function directly inside useEffect
    async function loadStrategyPricing() {
      // check to see if we lost on boarding state
      if (onboardStore.onboard.userStrategyId === null && userStrategyId !== null) {
        const success = await onboardStore.reloadOnboard(userStrategyId);
        if (!success) navigate('/home', { state: { userData: state?.userData }, replace: true });
        onboardStore.onboard = onboardStore.getOnboard(); // TODO
      }

      if (onboardStore.onboard.strategy?.id && onboardStore.onboard.userStrategyId)
        try {
          getStrategyDetail(onboardStore.onboard.strategy.id)
            .then(async (response) => {
              if (response.successful && response.payload && response.payload.pricing) {
                setStrategyDetails(response.payload);

                const billingResponse = await getUserStrategySubscription({
                  userStrategyId: onboardStore.onboard.userStrategyId!,
                  strategyPriceId: response.payload.pricing.strategyPriceId,
                });

                if (billingResponse.successful && billingResponse.payload) {
                  setSubscriptionInfo(billingResponse.payload);
                  setClientSecret(billingResponse.payload.clientSecret);
                } else {
                  setErrorMessage(
                    (billingResponse.errorMessages?.length || 0) < 1
                      ? 'Unable to retrieve billing option(s) for selected strategy.'
                      : response.errorMessages.toString()
                  );
                  setError(true);
                }
                //
              } else {
                setErrorMessage(
                  (response.errorMessages?.length || 0) < 1
                    ? 'Unable to retrieve pricing option(s) for selected strategy.'
                    : response.errorMessages.toString()
                );
                setError(true);
              }
              setIsLoading(false);
            })
            .catch((error) => {
              setErrorMessage(
                (error as AxiosError<IBaseResponse<StrategyDetailDto>>)?.response?.data?.errorMessages?.toString() || defaultLoadingErrorMessage
              );
              setError(true);
              setIsLoading(false);
            });

          //
        } catch (error) {
          setErrorMessage(
            (error as AxiosError<IBaseResponse<StrategyDetailDto>>)?.response?.data?.errorMessages?.toString() || defaultLoadingErrorMessage
          );
          setError(true);
          setIsLoading(false);
        }
    }

    async function checkUserState() {
      userState()
        .then(async (userResponse) => {
          if (!userResponse.successful || !userResponse.payload.isKYCVerified) {
            console.log('user is not kyc verified - return to dashboard');
            navigate('/home', { state: { userData: userResponse.payload }, replace: true });
          } else {
            loadStrategyPricing();
          }
        })
        .catch((error) => {
          console.log(error);
          navigate('/home', { state: { userData: null }, replace: true });
        });
    }

    checkUserState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading)
    return (
      <Center>
        <VStack mt="5%" w={{ base: '90vw', lg: '600px' }}>
          <BrandSpinner />
        </VStack>
      </Center>
    );

  return (
    <>
      <WindowAlert
        alertTitle="We are unable to process your request"
        alertMessage={errorMessage}
        alertFooter={`Please contact ${environment.SupportName}`}
        alertStatus="error"
        isOpen={error}
        onClose={() => setError(false)}
      />

      {/* https://v2.chakra-ui.com/docs/styled-system/responsive-styles */}
      <ChakraShow breakpoint="(min-width: 992px)">
        <Box position="absolute" top="0" right="0" padding="10px 22px">
          <OnboardProgressStatus events={onboardStore.onboard.onboardEvents} eventCount={null} completedEventCount={null} />
        </Box>
      </ChakraShow>
      <ChakraShow breakpoint="(max-width: 991px)">
        <Flex w="90vw" mb={6} mt={0}>
          <Spacer />
          <OnboardProgressStatus events={onboardStore.onboard.onboardEvents} eventCount={null} completedEventCount={null} />
        </Flex>
      </ChakraShow>

      <Flex flexDirection="column" justifyContent="start" w={{ base: '90vw', lg: 'calc(100vw - 350px)' }}>
        <ChakraShow breakpoint="(min-width: 992px)">
          <Text mb={{ base: '20px', lg: '0rem' }} textStyle={{ base: 'text-md-semibold', lg: 'display-sm-semibold' }} color="black">
            Complete Billing Information
          </Text>
        </ChakraShow>

        <Box w="100%">
          {strategyDetails && stripePromise && subscriptionInfo && (
            <Box mt="2%" w="100%">
              <Elements stripe={stripePromise} options={{ clientSecret, appearance, loader }}>
                <ConfigureBillingForm
                  paymentElementOptions={paymentElementOptions}
                  strategyDetails={strategyDetails}
                  subscriptionInfo={subscriptionInfo}
                />
              </Elements>
            </Box>
          )}
        </Box>
      </Flex>
    </>
  );
};
