import {
  Box,
  Button as ChakraButton,
  Center,
  Flex,
  HStack,
  Image as ChakraImage,
  Link as ChakraLink,
  Text,
  VStack,
  AbsoluteCenter,
} from '@chakra-ui/react';
import { faCircle, faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useRef, useState } from 'react';

import { BrandSpinner } from '../../../../components';
import { StrategyDetailDto } from '../../../../lib/types';

interface IConfirmBillingFormInputs {
  continueButtonText: string;
  onContinue: () => any;
  onViewDetailsClick: () => any;
  paymentIntentClientSecret: string;
  strategyDetails: StrategyDetailDto;
}

export const ConfirmBillingForm = ({
  continueButtonText,
  onContinue,
  onViewDetailsClick: onBillingDetailsClient,
  paymentIntentClientSecret,
  strategyDetails,
}: IConfirmBillingFormInputs) => {
  const stripe = useStripe();

  // handling page state
  const [intentId, setIntentId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentSuccessfulState, setPaymentSuccessfulState] = useState<boolean | null>(null);
  const [statusTitle, setStatusTitle] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    stripe
      .retrievePaymentIntent(paymentIntentClientSecret)
      .then(({ paymentIntent }) => {
        //
        if (!paymentIntent) {
          console.log('confirm billing form - payment intent not returned');
          setStatusTitle('Oops!');
          setStatusMessage('Something went wrong.');
          setIsLoading(false);
          return;
        }

        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer. [1]
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        // [1]: https://docs.stripe.com/payments/paymentintents/lifecycle#intent-statuses
        switch (paymentIntent.status) {
          case 'succeeded':
            setPaymentSuccessfulState(true);
            setStatusTitle('Success!');
            setStatusMessage('Your payment was successful.');
            break;

          case 'processing':
            setPaymentSuccessfulState(true);
            setStatusTitle('Success!');
            setStatusMessage("Payment is processing. We'll update you when payment is received.");
            break;

          case 'requires_payment_method':
            setPaymentSuccessfulState(false);
            setStatusTitle('Payment failed');
            setStatusMessage('We are sorry, there was an error processing your payment. Please try another payment method.');
            // Redirect your user back to your payment page to attempt collecting payment again
            // well... may or may not be able to pick-up the pi client secret
            break;

          default:
            // case 'cancelled':
            // case 'requires_confirmation':
            // case 'requires_action':
            setPaymentSuccessfulState(false);
            setStatusTitle('Payment failed');
            setStatusMessage('Something went wrong.');
            break;
        }
        setIntentId(paymentIntent.id);
        setIsLoading(false);
      })
      .catch(({ error }) => {
        console.log('retrievePaymentIntent error', error);
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe]);

  if (isLoading)
    return (
      <Center>
        <VStack mt="5%" w="600px">
          <BrandSpinner />
        </VStack>
      </Center>
    );

  return (
    <Box position="relative" mt={{ base: '4px', md: '170px' }} w={{ base: '90vw', lg: 'calc(100vw - 350px)' }} h={{ base: '85vh', lg: '80%' }}>
      <AbsoluteCenter
        axis="horizontal"
        padding={6}
        border={{ base: '0px solid', lg: '1px solid' }}
        borderColor="gray.500"
        borderRadius="8px"
        w={{ base: 'fit-content', lg: '620px' }}
      >
        <VStack w="100%">
          {/*  */}

          {/* Billing section */}
          <Box width="100%">
            <VStack
              alignItems="start"
              mb={0}
              py={6}
              px={4}
              border="1px"
              borderColor={paymentSuccessfulState === null ? '#F9FAFB' : paymentSuccessfulState === true ? '#17B26A' : '#FF5A5A'}
              bgColor={paymentSuccessfulState === null ? '#F9FAFB' : paymentSuccessfulState === true ? '#DDF6EB' : '#FFEFEF'}
              borderRadius="8px"
            >
              <HStack alignItems="center">
                <FontAwesomeIcon
                  icon={paymentSuccessfulState === null ? faCircle : paymentSuccessfulState ? faCircleCheck : faCircleExclamation}
                  fontSize="32px"
                  style={{ color: paymentSuccessfulState === null ? '#F9FAFB' : paymentSuccessfulState === true ? '#17B26A' : '#FF5A5A' }} // TODO: figure out how to use branding colors: e.g., green.700
                />
                <Text
                  as="span"
                  color={paymentSuccessfulState === null ? '#F9FAFB' : paymentSuccessfulState === true ? '#17B26A' : '#FF5A5A'}
                  textStyle={{ base: 'text-sm-bold', lg: 'text-lg-semibold' }}
                  ms={2}
                >
                  {statusTitle}
                </Text>
              </HStack>
              <Text as="span" color="gray.900" textStyle={{ base: 'text-xs-regular', lg: 'text-md-medium' }} ms={12}>
                {statusMessage}
                {intentId && (
                  <ChakraLink color="blue.700" onClick={() => onBillingDetailsClient()} textDecoration="none" ms={1} lineHeight="18px">
                    <Text
                      as="span"
                      textStyle={{ base: 'text-xs-regular', lg: 'text-md-medium' }}
                      textDecoration="none"
                      color="blue.700"
                      me={1}
                      lineHeight="18px"
                    >
                      View details
                    </Text>
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} fontSize="11px" color="blue.700" />
                  </ChakraLink>
                )}
              </Text>
            </VStack>
          </Box>

          {/* Strategy Info Section */}
          <Box mt={8} w="100%">
            <VStack alignItems={{ base: 'center', md: 'stretch' }} w="100%" gap={2}>
              <Text mt={{ base: '0px', lg: '8px' }} mb={1} color="gray.500" textStyle={{ base: 'text-xs-medium', lg: 'text-sm-semibold' }}>
                Subscribe to {strategyDetails.name}
              </Text>

              <HStack padding={0}>
                <ChakraImage src={'https://cdn.dynamicinvest.dev/logos/Ark_Invest_logo.svg'} h="40px" w="111px" me={6} />

                <Text as="span" textStyle={{ base: 'display-xs-semibold', lg: 'display-md-semibold' }} color="black">
                  {strategyDetails === null || strategyDetails.pricing === null || strategyDetails.pricing.pricePerUnit === null
                    ? 'N/A'
                    : strategyDetails!.pricing!.pricePerUnit.toLocaleString('en-US', {
                        style: 'currency',
                        currency: strategyDetails!.pricing!.currency,
                      })}
                </Text>

                <VStack gap={0}>
                  <Text as="span" textStyle={{ base: 'text-xs-regular', lg: 'text-sm-regular' }} color="gray.500" my={0}>
                    {strategyDetails === null || strategyDetails.pricing === null ? 'N/A' : strategyDetails!.pricing!.interval}
                  </Text>
                </VStack>
              </HStack>

              <Text
                my={1}
                color="gray.500"
                textStyle={{ base: 'text-xs-regular', lg: 'text-sm-regular' }}
                textAlign={{ base: 'center', lg: 'start' }}
              >
                Portfolio management of the {strategyDetails.name} strategy
              </Text>

              {/* Continue button */}
              <Flex flexDirection="column" justifyContent={{ base: 'stretch', md: 'flex-start' }} marginTop={8}>
                <ChakraButton
                  bgColor="blue.700"
                  border="1px"
                  borderColor="blue.700"
                  borderRadius="8px"
                  paddingX={4}
                  paddingY="14px"
                  type="button"
                  width={{ base: '90vw', md: 'fit-content' }}
                  onClick={() => onContinue()}
                >
                  <Text color="white" textStyle="text-md-medium">
                    {continueButtonText}
                  </Text>
                </ChakraButton>
              </Flex>
            </VStack>
          </Box>
        </VStack>
      </AbsoluteCenter>
    </Box>
  );
};
