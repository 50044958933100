import { Text } from '@chakra-ui/react';
import * as React from 'react';

// import { environment } from '../../../../environments/environment';

export type ContactSupportProps = React.HTMLAttributes<HTMLElement> & {
  displayErrors?: boolean;
};

export type ContactSupportMessage = {
  messageHeader: string;
  messages: string[];
};

export const ContactSupport = ({ displayErrors = false, ...props }: ContactSupportProps) => {
  //const supportEmail = environment.SupportEmail;
  return <Text>{props.children}</Text>;
};

ContactSupport.displayName = 'ContactSupport';
