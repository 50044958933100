import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryKey,
  UseQueryOptions,
  QueryFunction,
  MutationFunction,
  UseMutationOptions,
} from '@tanstack/react-query';
import React, { ReactElement } from 'react';

export interface AuthConfig<User, LoginCredentials, RegisterCredentials, ResetPasswordCredentials> {
  userFn: QueryFunction<User, QueryKey>;
  loginFn: MutationFunction<User, LoginCredentials>;
  registerFn: MutationFunction<unknown, RegisterCredentials>;
  resetPasswordFn: MutationFunction<unknown, ResetPasswordCredentials>;
  logoutFn: MutationFunction<unknown, unknown>;
  userKey?: QueryKey;
}

export interface AuthProviderProps {
  children: React.ReactNode;
}

// eslint-disable-next-line prettier/prettier
export function configureAuth<User, Error, LoginCredentials, RegisterCredentials, ResetPasswordCredentials>(
  config: AuthConfig<User, LoginCredentials, RegisterCredentials, ResetPasswordCredentials>
) {
  const { userFn, userKey = ['authenticated-user'], loginFn, registerFn, resetPasswordFn, logoutFn } = config;

  const useUser = (options?: Omit<UseQueryOptions<User, Error, User, QueryKey>, 'queryKey' | 'queryFn'>) =>
    useQuery({ queryKey: userKey, queryFn: userFn, ...options });

  const useLogin = (options?: Omit<UseMutationOptions<User, Error, LoginCredentials>, 'mutationFn'>) => {
    const queryClient = useQueryClient();

    const setUser = React.useCallback((data: User) => queryClient.setQueryData(userKey, data), [queryClient]);

    return useMutation({
      mutationFn: loginFn,
      ...options,
      onSuccess: (user, ...rest) => {
        setUser(user);
        options?.onSuccess?.(user, ...rest);
      },
    });
  };

  const useRegister = (options?: Omit<UseMutationOptions<unknown, Error, RegisterCredentials>, 'mutationFn'>) => {
    const queryClient = useQueryClient();

    const setUser = React.useCallback((data: User | null) => queryClient.setQueryData(userKey, data), [queryClient]);

    return useMutation({
      mutationFn: registerFn,
      ...options,
      onSuccess: (...args) => {
        setUser(null);
        options?.onSuccess?.(...args);
      },
    });
  };

  const useResetPassword = (options?: Omit<UseMutationOptions<unknown, Error, ResetPasswordCredentials>, 'mutationFn'>) => {
    const queryClient = useQueryClient();

    const setUser = React.useCallback((data: User | null) => queryClient.setQueryData(userKey, data), [queryClient]);

    return useMutation({
      mutationFn: resetPasswordFn,
      ...options,
      onSuccess: (...args) => {
        setUser(null);
        options?.onSuccess?.(...args);
      },
    });
  };

  const useLogout = (options?: UseMutationOptions<unknown, Error, unknown>) => {
    const queryClient = useQueryClient();

    const setUser = React.useCallback((data: User | null) => queryClient.setQueryData(userKey, data), [queryClient]);

    return useMutation({
      mutationFn: logoutFn,
      ...options,
      onSuccess: (...args) => {
        setUser(null);
        options?.onSuccess?.(...args);
      },
    });
  };

  function AuthLoader({
    children,
    renderLoading,
    renderUnauthenticated,
    renderError = (error: Error) => <>{JSON.stringify(error)}</>,
  }: {
    children: React.ReactNode;
    renderLoading: () => ReactElement;
    renderUnauthenticated?: () => ReactElement;
    renderError?: (error: Error) => ReactElement;
  }) {
    // stale time of 1 minutes for this hook
    const { isSuccess, isFetched, status, data, error } = useUser({ staleTime: 60000 });

    if (isSuccess) {
      if (renderUnauthenticated && !data) {
        return renderUnauthenticated();
      }
      return <div>{children}</div>;
    }

    if (!isFetched) {
      return renderLoading();
    }

    if (status === 'error') {
      return renderError(error);
    }

    return null;
  }

  return {
    useUser,
    useLogin,
    useRegister,
    useResetPassword,
    useLogout,
    AuthLoader,
  };
}
