import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Image,
  Link as ChakraLink,
  Show as ChakraShow,
  Spacer,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

import menuIcon from '../../../assets/icon-menu.svg';
import menuCloseIcon from '../../../assets/icon-x-close.svg';

import '@fontsource/manrope/400.css';

const Header = () => {
  const navigate = useNavigate();

  const [display, changeDisplay] = useState('none');

  return (
    <>
      <Flex pt={{ base: '.75rem', lg: '2rem' }} pb={{ base: '.5rem', lg: '0rem' }} px={{ base: '16px', lg: '84px' }}>
        <Flex alignItems="center">
          <Image
            src={'https://cdn.dynamicinvest.dev/logos/Ark_Invest_logo.svg'}
            h={{ base: '20px', lg: '50px' }}
            w={{ base: '96.25px', lg: '140px' }}
          />
        </Flex>

        <Spacer />

        {/* Large tablet & desktop */}
        <Flex alignItems="center" display={{ base: 'none', lg: 'flex' }}>
          <ChakraLink as={ReactRouterLink} to={'/auth/login'} pe={10} textStyle={'text-md-medium'}>
            Log in
          </ChakraLink>

          <Button onClick={() => navigate('/auth/register')} textStyle={'text-md-medium'}>
            Get Started
          </Button>
        </Flex>

        {/* phone & small tablet */}
        <IconButton
          aria-label="Open Menu"
          size="md"
          mr={2}
          bgColor="white"
          icon={<Image src={menuIcon} h="36px" w="36px" />}
          onClick={() => changeDisplay('flex')}
          display={{ base: 'flex', lg: 'none' }}
        />

        <Flex display={display} bgColor="white" zIndex={20} pos="fixed" top="6" right="4" overflowY="auto" flexDir="column">
          <Flex justify="flex-end">
            <IconButton
              mt={2}
              mr={2}
              aria-label="Open Menu"
              size="md"
              bgColor="white"
              icon={<Image src={menuCloseIcon} h="24px" w="24px" />}
              onClick={() => changeDisplay('none')}
            />
          </Flex>

          <Flex flexDir="column" align="flex-end" me={10}>
            <ChakraLink as={ReactRouterLink} to={'/auth/login'} textStyle={'text-md-medium'}>
              Log in
            </ChakraLink>

            <ChakraLink as={ReactRouterLink} to={'/auth/register'} textStyle={'text-md-medium'}>
              Get Started
            </ChakraLink>
          </Flex>
        </Flex>
      </Flex>
      <ChakraShow breakpoint="(max-width: 991px)">
        {/* lg: '62em', // ~992px */}
        <Divider />
      </ChakraShow>
    </>
  );
};

type AnonymousLayoutProps = {
  children: React.ReactNode;
};

export const AnonymousLayout = ({ children }: AnonymousLayoutProps) => {
  return (
    <Grid
      templateAreas={`"header header"
                      "main main"`}
      gridTemplateRows={{ base: '60px 1fr', lg: '100px 1fr' }}
      gridTemplateColumns={'1fr'}
      h="100vh"
      gap={1}
    >
      <GridItem area={'header'}>
        <Header />
      </GridItem>
      <GridItem area={'main'} pt={8} pb={4}>
        <Container maxW="7xl">{children}</Container>
      </GridItem>
    </Grid>
  );
};
