import {
  Box,
  Button as ChakraButton,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Image as ChakraImage,
  Input,
  Link as ChakraLink,
  ListItem,
  OrderedList,
  Show as ChakraShow,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faArrowUpRightFromSquare, faDisplay } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import coinbaseAdvancedLogo from '../../../../../assets/icon-CoinbaseAdvanced.svg';
import iconShieldCheck from '../../../../../assets/icon-shield-check.svg';
import katmanIcon from '../../../../../assets/katman.svg';
import { environment } from '../../../../../environments/environment';
import { BrandSpinner, CopyToClipboardButton, WindowAlert } from '../../../../components';
import { createUserExchange, getUserExchangeIpWhitelist } from '../../../../lib/apis';
import { IBaseResponse } from '../../../../lib/interfaces';
import { ApiKeyNameMaxLength, PortfolioNameMaxLength, PrivateKeyMaxLength, UserExchangeDto, UserExchangeRequest } from '../../../../lib/types';
import { ConnectCoinbaseInfo } from '../Elements/ConnectCoinbaseInfo';

export interface IConnectCoinbaseFormProps {
  userStrategyId: string;
  onSuccess: (success: boolean) => any;
}

interface IFormInput {
  portfolioName: string;
  apiKeyName: string;
  privateKey: string;
}

export const ConnectCoinbaseForm = ({ userStrategyId, onSuccess }: IConnectCoinbaseFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IFormInput>();

  const defaultLoadErrorMessage = 'We could not download the IP Address(es) to whitelist.';
  const defaultSaveErrorMessage = 'Failed to connect to Coinbase.';

  // handling page state
  const [connectError, setConnectError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Form content
  const [whitelistIPs, setWhitelistIPs] = useState('');

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  // Triggered more than once - use a reference variable to determine if component is mounted
  React.useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    // React advises to declare the async function directly inside useEffect
    async function loadExchangeSettings() {
      try {
        const response = await getUserExchangeIpWhitelist();
        if (response.successful === true && response.payload !== null && response.payload.length > 0) {
          setWhitelistIPs(response.payload);
        } else {
          setErrorMessage(defaultLoadErrorMessage);
          setConnectError(true);
        }
      } catch (error) {
        setErrorMessage((error as AxiosError<IBaseResponse<string>>)?.response?.data?.errorMessages?.toString() || defaultLoadErrorMessage);
        setConnectError(true);
      }

      setIsLoading(false);
    }

    loadExchangeSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Coinbase Set-up Complete
  const onSubmit = async (values: IFormInput) => {
    setIsSubmitting(true);
    try {
      const re = /\\n/g;
      const request: UserExchangeRequest = {
        portfolioName: values.portfolioName,
        apiKeyName: values.apiKeyName,
        privateKey: values.privateKey.replace(re, '\n'),
        userStrategyId: userStrategyId,
      };
      const response = await createUserExchange(request);

      if (response.successful) {
        onSuccess(true);
        setIsSubmitting(false);
        return;
      }

      setErrorMessage(response.errorMessages.length < 1 ? defaultSaveErrorMessage : response.errorMessages.toString());
      setConnectError(true);
      setIsSubmitting(false);
      //
    } catch (error) {
      const errMsgs = (error as AxiosError<IBaseResponse<UserExchangeDto>>)?.response?.data?.errorMessages || [{}];
      const isStringArray =
        Array.isArray(errMsgs) &&
        errMsgs.length > 0 &&
        errMsgs.every((value) => {
          return typeof value === 'string';
        });
      setErrorMessage(isStringArray ? errMsgs.toString() : defaultSaveErrorMessage);
      setConnectError(true);
      setIsSubmitting(false);
    }
  };

  if (isLoading)
    return (
      <>
        <Box w="403px" flexShrink={0}>
          <HStack mb={4}>
            <Text as="span" textStyle="display-xs-semibold" color="black">
              Connect
            </Text>{' '}
            <VStack alignItems="end" gap={0}>
              <ChakraImage src={coinbaseAdvancedLogo} h="27.4px" w="92px" />
              <Text
                as="span"
                color="#5B5B5B"
                fontSize="8.8px"
                fontWeight={500}
                fontStyle="normal"
                fontFamily={"'Plus Jakarta Sans', sans-serif"}
                lineHeight="normal"
                me="0.1rem"
                mt="-0.25rem"
              >
                Advanced
              </Text>
            </VStack>{' '}
            <Text as="span" textStyle="display-xs-semibold" color="black">
              Portfolio
            </Text>
          </HStack>
        </Box>
        <Center>
          <VStack mt="5%" w="600px">
            <BrandSpinner />
          </VStack>
        </Center>
      </>
    );

  return (
    <>
      <WindowAlert
        alertTitle="We are unable to process your request"
        alertMessage={errorMessage}
        alertFooter={`Please contact ${environment.SupportName}`}
        alertStatus="error"
        isOpen={connectError}
        onClose={() => setConnectError(false)}
      />

      <Flex gap={{ base: '0px', lg: '50px' }} direction={{ base: 'column', lg: 'row' }}>
        {/* mobile: Display Coinbase Video Here */}
        <ChakraShow breakpoint="(max-width: 991px)">
          <Flex
            alignItems="center"
            borderRadius="8px"
            border="1px solid"
            borderColor="brand.warning.700"
            background="brand.warning.100"
            flexShrink={0}
            gap="8px"
            padding="8px 30px 8px 16px"
            w="90vw"
          >
            <ChakraImage src={katmanIcon} h="24px" w="24px" />
            <Text as="span" textStyle="text-xs-regular" color="black">
              For Coinbase API key creation you may need to access Coinbase via desktop.{' '}
              <FontAwesomeIcon icon={faDisplay} fontSize="12px" color="gray.700" />
            </Text>
          </Flex>
          <Box w="90vw" my={8}>
            <ConnectCoinbaseInfo />
          </Box>
        </ChakraShow>

        {/* Coinbase Advanced form */}
        <Box w={{ base: '90vw', lg: '430px' }}>
          <HStack mb={4}>
            <Text as="span" textStyle="display-xs-semibold" color="black">
              Connect
            </Text>{' '}
            <VStack alignItems="end" gap={0}>
              <ChakraImage src={coinbaseAdvancedLogo} h="27.4px" w="92px" />
              <Text
                as="span"
                color="#5B5B5B"
                fontSize="8.8px"
                fontWeight={500}
                fontStyle="normal"
                fontFamily={"'Plus Jakarta Sans', sans-serif"}
                lineHeight="normal"
                me="0.1rem"
                mt="-0.25rem"
              >
                Advanced
              </Text>
            </VStack>{' '}
            <Text as="span" textStyle="display-xs-semibold" color="black">
              Portfolio
            </Text>
          </HStack>

          <Box h="fit-content" flexShrink={0} border="1px" borderColor="green.50" borderRadius={8} background="green.50" py={5} px={4}>
            <HStack padding={0} mb={3} w="100%">
              <ChakraImage src={iconShieldCheck} w="24px" h="24px" />
              <Text textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }} color="black" me={1}>
                Connect Portfolio Securely
              </Text>
              <ChakraLink color="blue.700" onClick={() => window.open(environment.SecurityBestPracticesUrl, '_blank')} textDecoration="none">
                <Text as="span" textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }} textDecoration="underline" color="blue.700" me={2}>
                  Full Guide <FontAwesomeIcon icon={faArrowUpRightFromSquare} fontSize="12px" color="blue.700" />
                </Text>
              </ChakraLink>
            </HStack>

            <OrderedList w="100%">
              <ListItem mb={3}>
                <Text as="span" textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }} color="black">
                  Login to your exchange account and go to the{' '}
                </Text>
                <ChakraLink color="blue.700" onClick={() => window.open(environment.CoinbaseAdvancedPortfolioUrl, '_blank')} textDecoration="none">
                  <Text as="span" textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }} textDecoration="underline" color="blue.700" me={2}>
                    Portfolio
                  </Text>
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} fontSize="12px" color="blue.700" />
                </ChakraLink>
              </ListItem>
              <ListItem mb={3}>
                <Text textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }} color="black">
                  Create a new portfolio
                </Text>
              </ListItem>
              <ListItem mb={3}>
                <Text textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }} color="black">
                  Transfer in the desired funds to allocate to this strategy
                </Text>
              </ListItem>
              <ListItem mb={3}>
                <Text as="span" textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }} color="black">
                  Go to the
                </Text>{' '}
                <ChakraLink color="blue.700" onClick={() => window.open(environment.CoinbaseAdvancedApiSettingsUrl, '_blank')} textDecoration="none">
                  <Text as="span" textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }} textDecoration="underline" color="blue.700" me={2}>
                    Api Settings
                  </Text>
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} fontSize="12px" color="blue.700" />
                </ChakraLink>
              </ListItem>
              <ListItem mb={3}>
                <Text textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }} color="black">
                  Create an API key and select the portfolio you just created
                </Text>
              </ListItem>
              <ListItem mb={3}>
                <Text textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }} color="black">
                  Select only read and trade permissions
                </Text>
              </ListItem>
              <ListItem mb={3} w="100%">
                <Text mb={1} textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }} color="black">
                  Copy/paste the following into the IP whitelist section:
                </Text>
                <HStack>
                  <Text
                    as="span"
                    px={1}
                    py={2}
                    background="white"
                    color="black"
                    borderRadius={1}
                    textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }}
                    w={{ base: 'Calc(100vw - 200px)', md: '280px' }}
                    h="33px"
                    noOfLines={1}
                  >
                    {whitelistIPs}
                  </Text>
                  <CopyToClipboardButton content={whitelistIPs} />
                </HStack>
              </ListItem>
              <ListItem>
                <Text textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }} color="black">
                  Once created, paste the generated data below:
                </Text>
              </ListItem>
            </OrderedList>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Text mt={6} textStyle="text-md-semibold">
              Portfolio Name:
            </Text>
            <FormControl isInvalid={!!errors.portfolioName} mb={1}>
              <Input
                id="portfolioName"
                color="brand.gray.400"
                placeholder="Enter Coinbase porfolio name"
                ps="1"
                textStyle="text-md-regular"
                type="text"
                variant="flushed"
                {...register('portfolioName', {
                  required: 'Portfolio Name is required',
                  maxLength: {
                    value: PortfolioNameMaxLength,
                    message: `Portfolio Name exceeds maximum length of ${PortfolioNameMaxLength} characters`,
                  },
                })}
              />
              <FormErrorMessage>{errors.portfolioName && errors.portfolioName.message}</FormErrorMessage>
            </FormControl>

            <Text mt={4} textStyle="text-md-semibold">
              API Key Name:
            </Text>
            <FormControl isInvalid={!!errors.apiKeyName} mb={1}>
              <Input
                id="apiKeyName"
                color="brand.gray.400"
                placeholder="Enter API Key Name"
                ps="1"
                textStyle="text-md-regular"
                type="text"
                autoComplete="off"
                variant="flushed"
                {...register('apiKeyName', {
                  required: 'API Key Name is required',
                  maxLength: {
                    value: ApiKeyNameMaxLength,
                    message: `API Key Name exceeds maximum length of ${ApiKeyNameMaxLength} characters`,
                  },
                })}
              />
              <FormErrorMessage>{errors.apiKeyName && errors.apiKeyName.message}</FormErrorMessage>
            </FormControl>

            <Text mt={4} textStyle="text-md-semibold">
              Private Key:
            </Text>
            <FormControl isInvalid={!!errors.privateKey} mb={1}>
              <Input
                id="privateKey"
                color="brand.gray.400"
                placeholder="Enter Private Key"
                ps="1"
                textStyle="text-md-regular"
                type="password"
                autoComplete="off"
                variant="flushed"
                {...register('privateKey', {
                  required: 'Private Key is required',
                  maxLength: {
                    value: PrivateKeyMaxLength,
                    message: `Private Key exceeds maximum length of ${PrivateKeyMaxLength} characters`,
                  },
                })}
              />
              <FormErrorMessage>{errors.privateKey && errors.privateKey.message}</FormErrorMessage>
            </FormControl>

            <Center w="100%" mt={5}>
              <ChakraButton
                border="2px"
                borderColor="brand.blue.700"
                bgColor={isSubmitting ? 'white' : 'brand.blue.700'}
                color={isSubmitting ? 'brand.blue.700' : 'white'}
                w="100%"
                borderRadius={8}
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                loadingText="Submitting"
                textStyle="text-md-medium"
                type="submit"
                spinnerPlacement="end"
                opacity={isSubmitting ? '0.6' : '1.0'}
              >
                Connect Coinbase Advanced
              </ChakraButton>
            </Center>
          </form>

          <VStack w={{ base: '90vw', lg: '100%' }} mt={{ base: '24px', lg: '12px' }}>
            <Text textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }}>Don’t have an account?</Text>
            <ChakraLink color="blue.700" onClick={() => window.open(environment.CoinbaseAdvancedSignUpUrl, '_blank')} textDecoration="none">
              <Text textStyle={{ base: 'text-sm-regular', md: 'text-sm-medium' }} textDecoration="underline" color="blue.700">
                Create a new Coinbase Advanced account
              </Text>
            </ChakraLink>
          </VStack>
        </Box>

        {/* tablet & desktop: Display Coinbase video here */}
        <ChakraShow breakpoint="(min-width: 992px)">
          <Box w="585px" mt="46px">
            <ConnectCoinbaseInfo />
          </Box>
        </ChakraShow>
      </Flex>
    </>
  );
};
