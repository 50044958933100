import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const Header = () => {
  const { pathname } = useLocation();

  const legalName = sessionStorage.getItem('legalName');

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (pathname.toLowerCase().indexOf('/user') > -1) return <>{''}</>;

  return (
    <Flex flexDirection="row" mb={8} alignContent="flex-start">
      <Text color="brand.black" textStyle={{ base: 'text-xl-regular', md: 'display-sm-semibold' }}>
        Welcome back{legalName && legalName.trim() !== '' ? `, ${legalName}` : ''}
      </Text>
    </Flex>
  );
};

Header.displayName = 'Header';
