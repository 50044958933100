import { Box, Button, Flex, Image, Link as ChakraLink, Spacer, Text, VStack, Tooltip } from '@chakra-ui/react';
import { faLayerGroup, faCog } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link as ReactRouterLink, useLocation, useNavigate } from 'react-router-dom';

import iconLogout from '../../../assets/icon-logout.svg';
import iconMail from '../../../assets/icon-mail.svg';
import iconMessageQuestion from '../../../assets/icon-message-question.svg';
import { environment } from '../../../environments/environment';
import { useLogout } from '../../lib/providers/auth';

export const SideNav = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const logoutFn = useLogout();

  const learningCenterLink = environment.LearningCenterUrl;
  const learningCenterName = environment.LearningCenterName;
  const supportEmail = environment.SupportEmail;
  const supportName = environment.SupportName;

  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

  const onLogoutUser = async () => {
    logoutFn.mutate(null, {
      onSuccess: () => {
        navigate('/auth/login');
      },
      onError: (error) => {
        console.log('error logging out?!', error);
      },
    });
  };

  const copyToClipboard = async (content: string) => {
    setIsCopiedToClipboard(false);
    try {
      await navigator.clipboard.writeText(content);
      setIsCopiedToClipboard(true);
    } catch (error) {
      console.error('Unable to copy to clipboard:', error);
    }
  };

  return (
    <Box pl={11} py={6} borderRight="1px solid" borderRightColor="brand.borderLight" minW="265px" h="100%">
      <Flex flexDirection="column" justifyContent="space-between" h="100%">
        <Box ml={8}>
          <ChakraLink as={ReactRouterLink} to={'/home'} state={state}>
            <Image src={'https://cdn.dynamicinvest.dev/logos/Ark_Invest_logo.svg'} h="40px" w="100px" />
          </ChakraLink>

          <VStack alignItems="start" mt="42px">
            <Button
              onClick={() => navigate('/home', { state: { userData: state?.userData } })}
              color={pathname.endsWith('/home') ? 'blue.700' : 'black'}
              textStyle="text-sm-medium"
              variant="ghost"
              padding={0}
              w="100%"
              justifyContent="start"
              mb={6}
              leftIcon={<FontAwesomeIcon icon={faLayerGroup} fontSize="24px" />}
            >
              <Text textStyle="text-sm-medium" ms="8px">
                Home
              </Text>
            </Button>

            <Button
              onClick={() => navigate('/user/profile', { state: { userData: state?.userData } })}
              color={pathname.startsWith('/user') ? 'blue.700' : 'black'}
              variant="ghost"
              padding={0}
              w="100%"
              justifyContent="start"
              mb={6}
              leftIcon={<FontAwesomeIcon icon={faCog} fontSize="24px" />}
            >
              <Text textStyle="text-sm-medium" ms="8px">
                Settings
              </Text>
            </Button>

            <Button
              onClick={() => onLogoutUser()}
              color="black"
              textStyle="text-sm-medium"
              variant="ghost"
              padding={0}
              w="100%"
              justifyContent="start"
              leftIcon={<Image src={iconLogout} h="24px" w="24px" />}
            >
              <Text textStyle="text-sm-medium" ms="8px">
                Logout
              </Text>
            </Button>
          </VStack>

          <Spacer />

          <VStack alignItems="start" position="absolute" bottom="40px">
            <Tooltip label={isCopiedToClipboard ? 'Copied!' : supportEmail} textStyle="text-sm-medium" closeDelay={500}>
              <Button
                onClick={() => {
                  copyToClipboard(supportEmail);
                  window.setTimeout(() => {
                    setIsCopiedToClipboard(false);
                  }, 5000);
                }}
                color="gray.500"
                textStyle="text-sm-medium"
                variant="ghost"
                padding={0}
                w="100%"
                justifyContent="start"
                leftIcon={<Image src={iconMail} h="24px" w="24px" />}
              >
                <Text as="span" textStyle="text-sm-medium" ms="8px">
                  {supportName}
                </Text>
              </Button>
            </Tooltip>

            <Button
              onClick={() => window.open(learningCenterLink, '_blank')}
              color="gray.500"
              textStyle="text-sm-medium"
              variant="ghost"
              padding={0}
              w="100%"
              justifyContent="start"
              leftIcon={<Image src={iconMessageQuestion} h="24px" w="24px" />}
            >
              <Text textStyle="text-sm-medium" ms="8px">
                {learningCenterName}
              </Text>
            </Button>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
};

SideNav.displayName = 'SideNav';
