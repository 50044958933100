import {
  Flex,
  HStack,
  Image as ChakraImage,
  Show as ChakraShow,
  Spacer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faCog } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import usdcIcon from '../../../../assets/icon-usdc.png';
import { SpotPosition, StrategyDto, UserStrategyDashboardDto } from '../../../lib/types';

interface IPortfolioInput {
  userStrategy: UserStrategyDashboardDto;
  strategy: StrategyDto | null;
}

export const Portfolio = ({ userStrategy, strategy }: IPortfolioInput) => {
  //--
  // format positions -> maybe a widget?
  function formatPositions(positions: SpotPosition[]) {
    // Apply the style to a div that renders your text
    return positions.map((position) => {
      return (
        <Tr key={position.asset}>
          <Td color="black" textStyle={{ base: 'text-xxs-regular', lg: 'text-sm-regular' }} ps={0}>
            <HStack padding={0} gap={1} alignContent="start">
              <ChakraImage
                src={position.asset?.toLocaleLowerCase() === 'usd' ? usdcIcon : position.assetImgUrl || ''}
                h={{ base: '20px', lg: '32px' }}
                w={{ base: '20px', lg: '32px' }}
              />
              <ChakraShow breakpoint="(min-width: 992px)">
                <Text color="black" textStyle={{ base: 'text-xxs-regular', lg: 'text-sm-regular' }} ms={2}>
                  {position.assetName}
                </Text>
              </ChakraShow>
              <Text color={{ base: 'black', lg: 'gray.300' }} textStyle={{ base: 'text-xxs-regular', lg: 'text-sm-regular' }} ms={0}>
                {position.asset}
              </Text>
            </HStack>
          </Td>
          <Td color="black" textStyle={{ base: 'text-xxs-regular', lg: 'text-sm-regular' }} textAlign="end">
            {position.totalBalanceFiat === 0.0 ? '$0.00' : position.totalBalanceFiat.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </Td>
          <ChakraShow breakpoint="(min-width: 992px)">
            <Td color="black" textStyle={{ base: 'text-xxs-regular', lg: 'text-sm-regular' }} textAlign="end">
              {position.totalBalanceCrypto === 0.0 ? '----' : position.totalBalanceCrypto.toLocaleString('en-US', {})}
            </Td>
          </ChakraShow>
          <Td color="black" textStyle={{ base: 'text-xxs-regular', lg: 'text-sm-regular' }} textAlign="end">
            {position.assetPrice === 0.0
              ? '----'
              : position.assetPrice.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
          </Td>
          <ChakraShow breakpoint="(min-width: 992px)">
            <Td textStyle={{ base: 'text-xxs-regular', lg: 'text-sm-regular' }} textAlign="end">
              <Text color="black" textStyle={{ base: 'text-xxs-regular', lg: 'text-sm-regular' }}>
                {position.avgEntryPrice?.value.toLocaleString('en-US', {
                  style: 'currency',
                  currency: position.avgEntryPrice?.currency ?? 'USD',
                }) || '----'}
              </Text>
            </Td>
          </ChakraShow>
          <Td color="black" textStyle={{ base: 'text-xxs-regular', lg: 'text-sm-regular' }} textAlign="end">
            {position.allocation === 0.0
              ? '----'
              : `${position.allocation.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 8 })}%`}
          </Td>
        </Tr>
      );
    });
  }

  return (
    <VStack width={{ base: '95vw', lg: 'calc(100vw - 350px)' }} px={6} py={6} border="1px" borderColor="gray.300" borderRadius="8px">
      <Flex w="100%" direction={{ base: 'column', lg: 'row' }} gap={{ base: 2, lg: 4 }}>
        <HStack mb={2} padding={0} w={{ base: '100%', lg: '20%' }}>
          {strategy && <ChakraImage src={strategy.logoURL ?? ''} w={{ base: '30px', md: '60px' }} h={{ base: '30px', md: '60px' }}></ChakraImage>}
          <Text as="span" color="gray.700" textStyle={{ base: 'text-md-semibold', lg: 'text-lg-bold' }} lineHeight="20px">
            {userStrategy.strategyName}
          </Text>
          <ChakraShow breakpoint="(max-width: 991px)">
            <Spacer />
            <FontAwesomeIcon icon={faCog} fontSize="20px" color="#D0D5DD" /> {/* TODO Figure out how to pass Chakra color */}
          </ChakraShow>
        </HStack>
        <ChakraShow breakpoint="(min-width: 992px)">
          <Flex w="80%" alignItems="stretch" py={5} px={6} borderRadius="8px" borderColor="blue.50" bgColor="blue.50" justifyContent="start">
            <VStack alignItems="start">
              <Text color="gray.500" textStyle="text-xs-medium">
                Total Balance
              </Text>
              <Text color="black" textStyle="text-sm-medium" ps={0} ms={0}>
                {userStrategy.exchangeAccountBalance?.totalBalance?.value.toLocaleString('en-US', {
                  style: 'currency',
                  currency: userStrategy.exchangeAccountBalance?.totalBalance?.currency ?? 'USD',
                }) || '----'}
              </Text>
            </VStack>
            <Spacer />
            <VStack alignItems="start">
              <Text color="gray.500" textStyle="text-xs-medium">
                Total Cash Equivalent Balance
              </Text>
              <Text color="black" textStyle="text-sm-medium" ps={0} ms={0}>
                {userStrategy.exchangeAccountBalance?.totalCashEquivalentBalance?.value.toLocaleString('en-US', {
                  style: 'currency',
                  currency: userStrategy.exchangeAccountBalance?.totalCashEquivalentBalance?.currency ?? 'USD',
                }) || '----'}
              </Text>
            </VStack>
            <Spacer />
            <VStack alignItems="start">
              <Text color="gray.500" textStyle="text-xs-medium">
                Total Crypto Balance
              </Text>
              <Text color="black" textStyle="text-sm-medium" ps={0} ms={0}>
                {userStrategy.exchangeAccountBalance?.totalCryptoBalance?.value.toLocaleString('en-US', {
                  style: 'currency',
                  currency: userStrategy.exchangeAccountBalance?.totalCryptoBalance?.currency ?? 'USD',
                }) || '----'}
              </Text>
            </VStack>
          </Flex>
          <FontAwesomeIcon icon={faCog} fontSize="20px" color="#D0D5DD" /> {/* TODO Figure out how to pass Chakra color */}
        </ChakraShow>
        <ChakraShow breakpoint="(max-width: 991px)">
          <Flex w="100%" alignItems="stretch" py={4} px={6} borderRadius="8px" borderColor="blue.100" bgColor="blue.100" justifyContent="start">
            <VStack alignItems="start" gap={0}>
              <Text color="gray.500" textStyle="text-xs-medium">
                Total Balance
              </Text>
              <Text color="black" textStyle="text-sm-medium" ps={0} ms={0}>
                {userStrategy.exchangeAccountBalance?.totalBalance?.value.toLocaleString('en-US', {
                  style: 'currency',
                  currency: userStrategy.exchangeAccountBalance?.totalBalance?.currency ?? 'USD',
                }) || '----'}
              </Text>
            </VStack>
          </Flex>
        </ChakraShow>
      </Flex>

      <TableContainer w={{ base: '85vw', lg: '100%' }} gap={0}>
        <Table variant="simple">
          <Thead textTransform="none">
            <Tr>
              <Th textTransform="none" textStyle={{ base: 'text-xxs-bold', lg: 'text-sm-bold' }} color="gray.500" ps={0}>
                Asset
              </Th>

              <ChakraShow breakpoint="(max-width: 991px)">
                <Th textTransform="none" textStyle={{ base: 'text-xxs-bold', lg: 'text-sm-bold' }} color="gray.500" textAlign="end">
                  Balance
                </Th>
              </ChakraShow>

              <ChakraShow breakpoint="(min-width: 992px)">
                <Th textTransform="none" textStyle={{ base: 'text-xxs-bold', lg: 'text-sm-bold' }} color="gray.500" textAlign="end">
                  Total Balance
                </Th>
                <Th textTransform="none" textStyle={{ base: 'text-xxs-bold', lg: 'text-sm-bold' }} color="gray.500" textAlign="end">
                  Total Crypto Balance
                </Th>
              </ChakraShow>

              <Th textTransform="none" textStyle={{ base: 'text-xxs-bold', lg: 'text-sm-bold' }} color="gray.500" textAlign="end">
                Price
              </Th>

              <ChakraShow breakpoint="(min-width: 992px)">
                <Th textTransform="none" textStyle={{ base: 'text-xxs-bold', lg: 'text-sm-bold' }} color="gray.500" textAlign="end">
                  Avg Entry Price
                </Th>
              </ChakraShow>
              <ChakraShow breakpoint="(max-width: 991px)">
                <Th textTransform="none" textStyle={{ base: 'text-xxs-bold', lg: 'text-sm-bold' }} color="gray.500" textAlign="end">
                  Alloc %
                </Th>
              </ChakraShow>

              <ChakraShow breakpoint="(min-width: 992px)">
                <Th textTransform="none" textStyle={{ base: 'text-xxs-bold', lg: 'text-sm-bold' }} color="gray.500" textAlign="end">
                  Allocation
                </Th>
              </ChakraShow>
            </Tr>
          </Thead>
          <Tbody>{userStrategy.exchangeAccountBalance?.spotPositions && formatPositions(userStrategy.exchangeAccountBalance.spotPositions)}</Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};
