import { Box, Center, Flex, Image, Show as ChakraShow, Text } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUser } from '../../../lib/providers/auth';
import { UserInfoDto } from '../../../lib/types';
import { determineLandingPage } from '../../../lib/utils/user-events';
import { LoginForm } from '../components/LoginForm';

export const Login = () => {
  // do not cache login so the approriate landing page can be identified
  const user = useUser();
  const navigate = useNavigate();

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  // Triggered more than once - use a reference variable to determine if component is mounted
  React.useEffect(() => {
    if (isMountedRef.current === true || user.isLoading) return;
    isMountedRef.current = true;

    if (user.data && user.data !== null && user.data !== undefined) {
      console.log('valid user session - redirecting');
      let targetUrl = determineLandingPage(user.data);
      if (targetUrl.startsWith('onboard')) targetUrl = '/home';
      navigate(`${targetUrl}`, { state: { userData: user.data }, replace: true });
    }

    // watch for when the user state finishes loading
  }, [navigate, user.data, user.isLoading]);

  const onSubmit = async (data: UserInfoDto | null) => {
    const targetUrl = determineLandingPage(data);
    navigate(`${targetUrl}`, { state: { userData: data }, replace: true });
  };

  return (
    <Flex>
      <ChakraShow breakpoint="(min-width: 992px)">
        <Center w="550px" me={100}>
          <Image src="https://cdn.dynamicinvest.dev/logos/dynamic-invest.svg" w="550px" h="545px" />
        </Center>
      </ChakraShow>

      <Box w="500" mt={{ base: '0rem', lg: '4rem' }}>
        <Text textStyle={{ base: 'display-md-bold', lg: 'display-2xl-bold' }} color="brand.black">
          Log in
        </Text>

        <Text color="brand.gray.500" my={5} textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
          Please enter email and password to access your account
        </Text>

        <LoginForm onSuccess={(data: UserInfoDto | null) => onSubmit(data)} />
      </Box>
    </Flex>
  );
};
