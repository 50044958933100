import Axios, { InternalAxiosRequestConfig } from 'axios';

import { environment } from '../../../environments/environment';
import { logout } from '../apis/logout';

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  const token = sessionStorage.getItem('accessToken');
  if (token) {
    config.headers.authorization = ` Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}

export const axios = Axios.create({
  baseURL: environment.DynamicInvestUrl,
  withCredentials: true,
});

axios.interceptors.request.use(authRequestInterceptor);

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;
    console.log('originalRequest', originalRequest);
    console.log('error', error);

    if (!error.response) {
      // Most likely a network error
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = sessionStorage.getItem('refreshToken');

      if (refreshToken) {
        console.log('entering refresh token code');
        try {
          // supply refreshToken in the Authorization header
          const localAxios = Axios.create({
            baseURL: environment.DynamicInvestUrl,
            // eslint-disable-next-line prettier/prettier
            headers: { 'Authorization': ` Bearer ${refreshToken}` },
            withCredentials: true,
          });

          const response = await localAxios.put('/auth');
          console.log('refresh token response:', response);

          const newAccessToken = response.data.payload.accessToken;
          sessionStorage.setItem('accessToken', newAccessToken);

          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

          return axios(originalRequest); //recall Api with new token
          //
        } catch (error) {
          // Handle token refresh failure
          // "logout" the user and re-authenticate by login again
          logout();
        }
      }
    }
    return Promise.reject(error);
  }
);
