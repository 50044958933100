import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { OnboardLayout } from '../../../components';

import { ConfigureBilling } from './ConfigureBilling';
import { ConfirmBilling } from './ConfirmBilling';
import { ConnectCoinbase } from './ConnectCoinbase';
import { SelectStrategy } from './SelectStrategy';
import { VerifyIdentity } from './VerifyIdentity';

export const OnboardRoutes = () => {
  return (
    <Routes>
      {/* These paths are prefaced with /onboard in the outer route definitions */}
      <Route path="/*" element={<OnboardLayout />}>
        <Route path="configureBilling" element={<ConfigureBilling />} />
        <Route path="confirmBilling" element={<ConfirmBilling />} />
        <Route path="connectCoinbase" element={<ConnectCoinbase />} />
        <Route path="selectStrategy" element={<SelectStrategy />} />
        <Route path="verifyIdentity" element={<VerifyIdentity />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Route>
    </Routes>
  );
};
