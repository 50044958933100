import { Box, Center, Flex, Show as ChakraShow, Spacer, Text, VStack } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { environment } from '../../../../environments/environment';
import { BrandSpinner, OnboardProgressStatus, WindowAlert } from '../../../components';
import { getUserStrategy } from '../../../lib/apis';
import { IBaseResponse } from '../../../lib/interfaces';
import { UserStrategyDto } from '../../../lib/types';
import { ConnectCoinbaseForm } from '../components/Form/ConnectCoinbaseForm';
import { ConfirmationModal } from '../components/Modal/ConfirmationModal';
import { getOnboardUserStrategyId, getUrlForNextOnboardEvent, useOnboardStore } from '../stores/onboard';

export const ConnectCoinbase = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  // TODO: handle mutating state better
  const onboardStore = useOnboardStore();

  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    const userStrategyId = getOnboardUserStrategyId();

    // React advises to declare the async function directly inside useEffect
    async function loadOnboardingDetails() {
      // check to see if we lost on boarding state
      if (userStrategyId !== null) {
        const success = await onboardStore.reloadOnboard(userStrategyId);
        if (!success) navigate('/home', { state: { userData: state?.userData }, replace: true });
        onboardStore.onboard = onboardStore.getOnboard(); // TODO
      }
    }

    if (onboardStore.onboard.userStrategyId === null || onboardStore.onboard.userStrategyId !== userStrategyId) {
      loadOnboardingDetails();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirmed = async () => {
    setConfirmed(true);

    try {
      const response = await getUserStrategy(onboardStore.onboard.userStrategyId || '');
      if (response.successful) {
        onboardStore.onboard.onboardEvents = response.payload.userStrategyOnboardingStatus.onboardingEvents;
        navigate(`${await getUrlForNextOnboardEvent(onboardStore.onboard.onboardEvents)}`, { state: { userData: state?.userData }, replace: true });
        return;
      }

      setErrorMessage((response.errorMessages?.length || 0) < 1 ? 'Unable to load onboarding options.' : response.errorMessages.toString());
      setError(true);
      //
    } catch (error) {
      setErrorMessage(
        (error as AxiosError<IBaseResponse<UserStrategyDto>>)?.response?.data?.errorMessages?.toString() || 'Unable to update portfolio status.'
      );
      setError(true);
    }
  };

  return (
    <>
      <WindowAlert
        alertTitle="We are unable to process your request"
        alertMessage={errorMessage}
        alertFooter={`Please contact ${environment.SupportName}`}
        alertStatus="error"
        isOpen={error}
        onClose={() => setError(false)}
      />

      {/* TODO: When caching strategies, include event count */}
      <ChakraShow breakpoint="(min-width: 992px)">
        <Box position="absolute" top="0" right="0" padding="6px 28px">
          <OnboardProgressStatus events={onboardStore.onboard.onboardEvents} eventCount={null} completedEventCount={null} />
        </Box>
      </ChakraShow>
      <ChakraShow breakpoint="(max-width: 991px)">
        <Flex w="90vw" mb={6} mt={0}>
          <Spacer />
          <OnboardProgressStatus events={onboardStore.onboard.onboardEvents} eventCount={null} completedEventCount={null} />
        </Flex>
      </ChakraShow>

      <Flex flexDirection="column" justifyContent="start" w="100%">
        <Box w="100%">
          {confirmed && (
            <Center>
              <VStack mt="5%" w={{ base: '80vw', md: '600px' }}>
                <BrandSpinner />
              </VStack>
            </Center>
          )}

          {!confirmed && (
            <>
              <ConfirmationModal
                isOpen={submitted && !confirmed}
                onClose={async () => onConfirmed()}
                onContinueButtonClick={async () => onConfirmed()}
                headerText="Congratulations!"
                continueButtonText="Continue"
              >
                <VStack alignContent="stretch">
                  <Center>
                    <Text color="brand.gray.500" my={5} textStyle={{ base: 'text-md-regular', md: 'text-xl-regular' }} lineHeight={6}>
                      You have successfully connected your Coinbase portfolio.
                    </Text>
                  </Center>
                </VStack>
              </ConfirmationModal>

              <ConnectCoinbaseForm userStrategyId={onboardStore.onboard.userStrategyId || ''} onSuccess={() => setSubmitted(true)} />
            </>
          )}
        </Box>
      </Flex>
    </>
  );
};
