import { Box, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';

import { environment } from '../../../../../environments/environment';

export const ConnectCoinbaseInfo = () => {
  return (
    <>
      <VStack w="100%" alignItems="start">
        <Text textStyle={{ base: 'text-lg-medium', md: 'display-xs-semibold' }} color="black" mb={{ base: '16px', md: '20px' }}>
          Please see instructional video below:
        </Text>
      </VStack>
      <Box w={{ base: '87vw', md: 'calc((100vw - 350px) * 0.6)' }}>
        <video controls autoPlay muted playsInline>
          <track kind="captions" />
          <source src={environment.ConnectToCoinbaseVideoUrl} type="video/mp4" />
        </video>
      </Box>
    </>
  );
};
