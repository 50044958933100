import { Box, HStack, Link as ChakraLink, Text, VStack } from '@chakra-ui/react';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

import { environment } from '../../../environments/environment';
import { getUserSettingsBilling, IBaseResponse, UserSettingsBillingResponse } from '../../lib';
import { WindowAlert } from '../Elements';

const defaultLoadErrorMessage = 'We are unable to access your billing information.';

export const SettingsNav = () => {
  const { pathname, state } = useLocation();

  const [billingIsLoading, setBillingIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);

  const isProfileView = !pathname.endsWith('/billing');

  // open billing site in new window
  const onBillingClick = async () => {
    setBillingIsLoading(true);
    try {
      const response = await getUserSettingsBilling();
      if (response.successful === true && response.payload) {
        window.open(response.payload?.sessionURL, '_blank');
        setBillingIsLoading(false);
      } else {
        setErrorMessage(response.errorMessages.length < 1 ? defaultLoadErrorMessage : response.errorMessages.toString());
        setHasError(true); // triggers window alert
      }
      setBillingIsLoading(false);
    } catch (error) {
      setErrorMessage(
        (error as AxiosError<IBaseResponse<UserSettingsBillingResponse>>)?.response?.data?.errorMessages?.toString() || defaultLoadErrorMessage
      );
      setHasError(true);
      setBillingIsLoading(false);
    }
  };

  return (
    <>
      <WindowAlert
        alertTitle="We are unable to process your request"
        alertMessage={errorMessage}
        alertFooter={`Please contact ${environment.SupportName}`}
        alertStatus="error"
        isOpen={hasError}
        onClose={() => setHasError(false)}
      />
      <VStack width={{ base: '80vw', lg: 'calc(100vw - 350px)' }} align="start">
        <Text color="gray.900" textStyle="display-sm-bold">
          Settings
        </Text>

        <Box width={{ base: '80vw', lg: 'calc(100vw - 350px)' }} borderBottom="1px solid" borderBottomColor="gray.200" my={6}>
          <HStack gap={8} maxWidth="fit-content">
            <ChakraLink
              as={ReactRouterLink}
              to={'/user/profile'}
              state={state}
              textStyle={'text-sm-semibold'}
              paddingBottom={1}
              borderBottom={isProfileView ? '2px solid' : '0px solid'}
              borderBottomColor="blue.700"
            >
              Profile
            </ChakraLink>

            <ChakraLink
              onClick={billingIsLoading ? () => false : () => onBillingClick()}
              textStyle={'text-sm-semibold'}
              paddingBottom={1}
              textDecoration="none"
            >
              <Text as="span" textStyle={{ base: 'text-xs-regular', lg: 'text-sm-regular' }} textDecoration="none" me={1}>
                Billing
              </Text>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} fontSize="12px" color="blue.700" />
            </ChakraLink>
          </HStack>
        </Box>
      </VStack>
    </>
  );
};

SettingsNav.displayName = 'SettingsNav';
