import { Box, Center, Flex, Image, Show as ChakraShow, Text } from '@chakra-ui/react';
import React from 'react';

import dynamicInvestSvg from '../../../../assets/dynamic-invest.svg';

export const ConfirmEmail = () => {
  return (
    <Flex>
      <ChakraShow breakpoint="(min-width: 992px)">
        <Center w="550px" me={100}>
          <Image src={dynamicInvestSvg} w="550px" h="545px" />
        </Center>
      </ChakraShow>

      <Box w="500px" mt={{ base: '0rem', lg: '4rem' }}>
        <Text textStyle={{ base: 'display-md-bold', lg: 'display-2xl-bold' }} color="brand.black">
          Confirm your email address
        </Text>

        <Text color="brand.gray.500" mt={6} textStyle={{ base: 'text-sm-semibold', lg: 'text-xl-semibold' }}>
          Your information was submitted successfully!
        </Text>

        <Text as="span" color="brand.gray.500" mb={0} textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
          Please check your email and click the{' '}
          <Text as="span" color="brand.gray.500" textStyle={{ base: 'text-sm-semibold', lg: 'text-xl-semibold' }}>
            “Validate my email”{' '}
          </Text>
        </Text>

        <Text as="span" color="brand.gray.500" mb={5} textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
          link to validate your email address.
        </Text>
      </Box>
    </Flex>
  );
};
