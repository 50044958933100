import {
  Box,
  Button as ChakraButton,
  Card,
  CardBody,
  Flex,
  Image as ChakraImage,
  HStack,
  Show as ChakraShow,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faCircle, faCircleCheck, faCircleDot } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export type NextUpProps = React.HTMLAttributes<HTMLElement> & {
  data: NextUpDto;
  displaySteps?: boolean;
};

export type NextUpStepType = {
  name: string;
  isCurrent: boolean;
  isComplete: boolean;
};

export type NextUpDto = {
  strategyLogoUrl: string;
  strategyName: string;
  stepTitle: string;
  stepMessage: string;
  stepUrl: string;
  isStepDisabled: boolean;
  disabledMessage: string;
  buttonText?: string;
  nextSteps: NextUpStepType[];
};

export const NextUp = ({ data, displaySteps = false, ...props }: NextUpProps) => {
  // Map next steps to list items
  const nextStepItems = data.nextSteps.map((value) => {
    return (
      <Flex key={value.name} justifyContent="start" mb={1} alignItems="center">
        <FontAwesomeIcon
          icon={value.isComplete ? faCircleCheck : value.isCurrent ? faCircleDot : faCircle}
          fontSize={value.isCurrent ? '16px' : '14px'}
          style={{ color: value.isComplete ? '#17B26A' : 'black' }} // TODO: figure out how to use branding colors: green.700
        />
        <Text color="black" textStyle={value.isCurrent ? 'text-sm-bold' : 'text-sm-regular'} ms={2}>
          {value.name}
        </Text>
      </Flex>
    );
  });

  return (
    <Card width={{ base: '95vw', lg: 'calc(100vw - 350px)' }} px={6} py={6} border="1px" borderColor="gray.300" borderRadius="8px">
      <CardBody padding={0}>
        <VStack alignItems="stretch" spacing={0}>
          <HStack mb={2} padding={0}>
            <ChakraImage src={data.strategyLogoUrl} w={{ base: '30px', md: '60px' }} h={{ base: '30px', md: '60px' }}></ChakraImage>
            <Text as="span" color="gray.700" textStyle={{ base: 'text-xl-medium', lg: 'display-sm-semibold' }}>
              {data.strategyName}
            </Text>
          </HStack>

          <Text color="gray.500" mb={4} textStyle={{ base: 'text-xs-regular', lg: 'text-sm-regular' }}>
            {!data.isStepDisabled ? 'Please continue onboarding' : data.disabledMessage}
          </Text>

          <Flex>
            <VStack alignItems="start" gap={0} me={2}>
              <Text color="gray.700" textStyle="text-md-semibold" padding={0} alignItems="stretch" mb={0}>
                Next up
              </Text>
              <HStack>
                <VStack alignItems="stretch" spacing={0} me={1}>
                  <Text color="black" textStyle="text-xl-bold" mb={0}>
                    {data.stepTitle}
                  </Text>
                  <Text color="gray.500" textStyle="text-sm-regular">
                    {data.stepMessage}
                  </Text>
                </VStack>

                <Spacer />

                {data.buttonText && (
                  <Box h="100%" alignContent="flex-end" mb={4} ms={4}>
                    <ChakraButton
                      borderRadius="8px"
                      bgColor="blue.700"
                      textStyle="text-md-medium"
                      mt={4}
                      onClick={props.onClick}
                      isDisabled={data.isStepDisabled}
                    >
                      {data.buttonText}
                    </ChakraButton>
                  </Box>
                )}
              </HStack>
            </VStack>

            {/* https://v2.chakra-ui.com/docs/styled-system/responsive-styles */}
            <ChakraShow breakpoint="(min-width: 992px)">
              <Spacer />

              {displaySteps && data.nextSteps !== null && data.nextSteps !== undefined && data.nextSteps.length > 0 && (
                <Box borderLeft="1px" borderLeftColor="brand.border" paddingLeft="26px">
                  {nextStepItems}
                </Box>
              )}
            </ChakraShow>
          </Flex>
        </VStack>
      </CardBody>
    </Card>
  );
};

NextUp.displayName = 'NextUp';
