import { Box, Center, Flex, Show as ChakraShow, Spacer, Text, VStack } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { environment } from '../../../../environments/environment';
import { BrandSpinner, OnboardProgressStatus, WindowAlert } from '../../../components';
import { addUserStrategy, UserStrategyOnboardRequest } from '../../../lib/apis';
import { IBaseResponse } from '../../../lib/interfaces';
import { StrategyDto, UserStrategyDto } from '../../../lib/types';
import { SelectStrategyForm } from '../components/Form/SelectStrategyForm';
import { getUrlForNextOnboardEvent, useOnboardStore } from '../stores/onboard';

export const SelectStrategy = () => {
  const { state } = useLocation();
  console.log('select strategy: state passed via useLocation', state);
  const navigate = useNavigate();

  const setOnboard = useOnboardStore((state) => state.setOnboard);

  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  // Triggered more than once - use a reference variable to determine if component is mounted
  useEffect(() => {
    if (isMountedRef) return; // So we do not wipe out onboard state!@

    setOnboard({
      strategy: null,
      onboardEvents: null,
      userStrategyId: null,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // User confirmed strategy selection
  const onConfirmed = async (values: StrategyDto) => {
    setConfirmed(true);

    try {
      const request: UserStrategyOnboardRequest = {
        strategyId: values.id,
      };
      const response = await addUserStrategy(request);

      if (!response.successful || response.payload.userStrategyOnboardingStatus.onboardingEvents.length < 1) {
        setErrorMessage((response.errorMessages?.length || 0) < 1 ? 'Unable to load onboarding options.' : response.errorMessages.toString());
        setError(true);
      } else {
        setOnboard({
          strategy: values,
          onboardEvents: response.payload.userStrategyOnboardingStatus.onboardingEvents,
          userStrategyId: response.payload.userStrategyId,
        });
        navigate(`${await getUrlForNextOnboardEvent(response.payload.userStrategyOnboardingStatus.onboardingEvents)}`, {
          state: { userData: state?.userData },
          replace: true,
        });
        return;
      }
    } catch (error) {
      setErrorMessage(
        (error as AxiosError<IBaseResponse<UserStrategyDto>>)?.response?.data?.errorMessages?.toString() || 'Failed to save strategy selection.'
      );
      setError(true);
    }
  };

  return (
    <>
      <WindowAlert
        alertTitle="We are unable to process your request"
        alertMessage={errorMessage}
        alertFooter={`Please contact ${environment.SupportName}`}
        alertStatus="error"
        isOpen={error}
        onClose={() => setError(false)}
      />

      {/* https://v2.chakra-ui.com/docs/styled-system/responsive-styles */}
      <ChakraShow breakpoint="(min-width: 992px)">
        <Box position="absolute" top="0" right="0" padding="10px 22px">
          <OnboardProgressStatus events={null} eventCount={4} completedEventCount={0} />
        </Box>
      </ChakraShow>

      <Flex flexDirection="column" justifyContent="start" w={{ base: '80vw', lg: '100%' }}>
        {/* contained */}
        <ChakraShow breakpoint="(max-width: 991px)">
          <Flex w="80vw" mb={6} mt={0}>
            <Spacer />
            <OnboardProgressStatus events={null} eventCount={4} completedEventCount={0} />
          </Flex>
        </ChakraShow>

        <Text textStyle={{ base: 'text-md-semibold', lg: 'display-md-semibold' }} color="brand.black" mb={{ base: '20px', lg: '0rem' }}>
          Select your investment strategy
        </Text>

        <Box w={{ base: '80vw', lg: '100%' }}>
          {confirmed && (
            <Center>
              <VStack mt="5%" w={{ base: '80vw', lg: '600px' }}>
                <BrandSpinner />
              </VStack>
            </Center>
          )}

          {!confirmed && (
            <Box mt="2%" w={{ base: '80vw', lg: '100%' }}>
              <Box w="100%">
                <SelectStrategyForm onSuccess={(values) => onConfirmed(values.strategy)} />
              </Box>
            </Box>
          )}
        </Box>
      </Flex>
    </>
  );
};
