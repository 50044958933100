import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Button as ChakraButton,
  Flex,
  HStack,
  Image,
  Show as ChakraShow,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import { StrategyDetailDto, StrategyDto } from '../../../../lib/types';

type StrategyInformationModalProps = {
  isCentered?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSelectStrategy: (strategy: StrategyDto) => void;
  strategy: StrategyDto;
  strategyDetail: StrategyDetailDto | null;
};

const boxStyle = {
  boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.10)',
};

export const StrategyInformationModal = ({
  isCentered = true,
  isOpen,
  onClose,
  onSelectStrategy,
  strategy,
  strategyDetail,
}: StrategyInformationModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
      <ModalOverlay />
      <ModalContent maxW="80vw" px={{ base: 2, lg: 5 }} pt={{ base: 2, lg: 8 }} pb={{ base: 2, lg: 5 }} my={{ base: 0, lg: 12 }}>
        <ModalCloseButton />
        <ModalBody borderRadius="8px" w="100%" h="100%" sx={{ boxStyle }}>
          <Flex border="1px solid" borderColor="brand.borderLight" borderRadius={8} alignItems="stretch" px={4} py={5}>
            <VStack alignItems="stretch" w="100%">
              {/* */}

              {/* Strategy Icon & Name */}
              <Box mb={3}>
                <HStack w="100%">
                  <Image src={strategy.logoURL || ''} w={{ base: '40px', lg: '79px' }} h={{ base: '40px', lg: '79px' }} me={2}></Image>
                  <Text as="span" color="black" mb={0} textStyle={{ base: 'text-xl-medium', lg: 'display-sm-semibold' }}>
                    {strategy.name}
                  </Text>
                </HStack>
              </Box>

              {/* Sections */}
              <Flex w="100%" mb={6} alignItems="start" flexDirection={{ base: 'column', lg: 'row' }}>
                {/* Why Invest? */}
                <VStack w={{ base: '100%', lg: '55%' }}>
                  <Box w="100%" paddingBottom={3} borderBottom="1px solid" borderBottomColor="black">
                    <Text color="black" textStyle={{ base: 'text-md-medium', lg: 'text-xl-medium' }} mt={0} ml={0}>
                      Why invest in {strategy.name}?
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text w="100%" color="gray.500" textStyle={{ base: 'text-xxs-regular', lg: 'text-sm-regular' }} mt={0} ml={0}>
                      {strategyDetail?.description ?? strategy.shortDescription}
                    </Text>
                  </Box>
                </VStack>

                <Spacer />

                {/* Details */}
                <Box w={{ base: '100%', lg: '40%' }} mt={{ base: 6, lg: 0 }}>
                  <VStack mb={4} alignItems="stretch">
                    <Box w="100%" paddingBottom={3} borderBottom="1px solid" borderBottomColor="black">
                      <Text color="black" textStyle={{ base: 'text-md-medium', lg: 'text-xl-medium' }} mt={0} ml={0}>
                        Details
                      </Text>
                    </Box>
                    <HStack padding={0} mb={1}>
                      <Text textStyle={{ base: 'text-xs-medium', lg: 'text-md-medium' }} color="black">
                        Manager:
                      </Text>
                      <Spacer />
                      <Text as="span" textStyle={{ base: 'text-xs-bold', lg: 'text-md-bold' }} color="black" textAlign="end">
                        {strategyDetail?.managerName ?? 'N/A'}
                      </Text>
                    </HStack>

                    <HStack padding={0} mb={1}>
                      <Text textStyle={{ base: 'text-xs-medium', lg: 'text-md-medium' }} color="black">
                        Inception Date:
                      </Text>
                      <Spacer />
                      <Text as="span" textStyle={{ base: 'text-xs-bold', lg: 'text-md-bold' }} color="black" textAlign="end">
                        {strategyDetail === null || strategyDetail.inceptionDate === null
                          ? 'N/A'
                          : (strategyDetail!.inceptionDate instanceof Date
                              ? strategyDetail!.inceptionDate
                              : new Date(strategyDetail!.inceptionDate)
                            ).toLocaleDateString('en', {
                              year: 'numeric',
                              day: '2-digit',
                              month: 'long',
                            })}
                      </Text>
                    </HStack>

                    <HStack padding={0} mb={1}>
                      <Text textStyle={{ base: 'text-xs-medium', lg: 'text-md-medium' }} color="black">
                        Subscription Type
                      </Text>
                      <Spacer />
                      <Text as="span" textStyle={{ base: 'text-xs-bold', lg: 'text-md-bold' }} color="black">
                        {strategyDetail === null || strategyDetail.pricing === null ? 'N/A' : strategyDetail!.pricing!.pricingType}
                      </Text>
                    </HStack>

                    <HStack padding={0} mb={1}>
                      <Text textStyle={{ base: 'text-xs-medium', lg: 'text-md-medium' }} color="black">
                        Subscription Interval
                      </Text>
                      <Spacer />
                      <Text as="span" textStyle={{ base: 'text-xs-bold', lg: 'text-md-bold' }} color="black">
                        {strategyDetail === null || strategyDetail.pricing === null ? 'N/A' : strategyDetail!.pricing!.interval}
                      </Text>
                    </HStack>

                    <HStack padding={0} mb={1}>
                      <Text textStyle={{ base: 'text-xs-medium', lg: 'text-md-medium' }} color="black">
                        Price
                      </Text>
                      <Spacer />
                      <Text as="span" textStyle={{ base: 'text-xs-bold', lg: 'text-md-bold' }} color="black">
                        {strategyDetail === null || strategyDetail.pricing === null || strategyDetail.pricing.pricePerUnit === null
                          ? 'N/A'
                          : strategyDetail!.pricing!.pricePerUnit.toLocaleString('en-US', {
                              style: 'currency',
                              currency: strategyDetail!.pricing!.currency,
                            })}
                      </Text>
                    </HStack>
                  </VStack>
                </Box>
              </Flex>

              {/* Horizontally Stack Buttons */}
              <Flex w="100%" mt={{ base: 1, lg: 6 }} gap={2}>
                <ChakraButton
                  bgColor="white"
                  border="1px"
                  borderColor="blue.700"
                  borderRadius="8px"
                  paddingX={4}
                  paddingY="14px"
                  onClick={() => window.open(strategyDetail?.factSheetUrl ?? '', '_blank')}
                  type="button"
                >
                  <Text color="blue.700" textStyle="text-md-regular">
                    Fact Sheet
                  </Text>
                </ChakraButton>

                <Spacer />

                <ChakraButton
                  bgColor="blue.700"
                  border="1px"
                  borderRadius="8px"
                  paddingX={4}
                  paddingY="14px"
                  onClick={() => onSelectStrategy(strategy)}
                  type="button"
                >
                  <ChakraShow breakpoint="(min-width: 992px)">
                    <Text color="white" textStyle="text-md-regular">
                      Select Strategy
                    </Text>
                  </ChakraShow>
                  <ChakraShow breakpoint="(max-width: 991px)">
                    <Text color="white" textStyle="text-md-regular">
                      Select
                    </Text>
                  </ChakraShow>
                </ChakraButton>
              </Flex>
            </VStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
