import { Box, Flex, Image, Spacer, VStack } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const AlternateLayout = () => {
  return (
    <Flex h="100vh" w="100vw">
      <Box flex={1} px={6}>
        <VStack>
          <Image
            src={'https://cdn.dynamicinvest.dev/logos/Ark_Invest_logo.svg'}
            h={{ base: '20px', lg: '30px' }}
            w="96.25px"
            mt={{ base: '18px', lg: '58px' }}
          />

          <Spacer />
        </VStack>

        {/* This is the content for the route target */}
        <Outlet />
      </Box>
    </Flex>
  );
};
