import { Box, Center, VStack, useMediaQuery } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { environment } from '../../../../../environments/environment';
import { BaseFormProps, BrandSpinner, WindowAlert } from '../../../../components';
import { getStrategies, getStrategyDetail } from '../../../../lib/apis';
import { IBaseResponse } from '../../../../lib/interfaces';
import { StrategyDto, StrategiesDto, StrategyDetailDto, EmptyStrategy } from '../../../../lib/types';
import { StrategyInfo } from '../Elements/StrategyInfo';
import { StrategyInformationModal } from '../Modal/StrategyInformationModal';

interface IFormInput {
  strategy: StrategyDto;
}

interface IStrategyOption {
  strategyId: string;
  strategy: StrategyDto;
  strategyDetail: StrategyDetailDto | null;
}

const NoStrategyOptions: IStrategyOption[] = [];

export const SelectStrategyForm = ({ onSuccess }: BaseFormProps<IFormInput>) => {
  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<IFormInput>();

  // https://v2.chakra-ui.com/docs/styled-system/responsive-styles
  const [isLargerThanTablet] = useMediaQuery('(min-width: 62em)');

  const defaultErrorMessage = 'Failed to download strategy options.';

  // handling page state
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // handling Modal state
  const [isOpen, setIsOpen] = useState(false);
  const [modalStrategy, setModalStrategy] = useState<StrategyDto>(EmptyStrategy);
  const [modalStrategyDetail, setModalStrategyDetail] = useState<StrategyDetailDto | null>(null);

  // handling strategy options
  const [strategyOptions, setStrategyOptions] = useState<IStrategyOption[]>(NoStrategyOptions);

  // format strategy options
  function formatStrategyOptions(options: IStrategyOption[]) {
    // Apply the style to a div that renders your text
    return options.map((option) => {
      return (
        <StrategyInfo
          key={option.strategy.id}
          formIsSubmitting={isSubmitting}
          onClickModal={(strategy: StrategyDto, strategyDetail: StrategyDetailDto | null) => {
            setModalStrategy(strategy);
            setModalStrategyDetail(strategyDetail);
            setIsOpen(true);
          }}
          onSelectStrategy={(strategy: StrategyDto) => {
            setValue('strategy', strategy);
            if (formRef !== null) formRef.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
          }}
          strategy={option.strategy}
          strategyDetail={option.strategyDetail}
        />
      );
    });
  }

  // Get handle to form in order to "submit" the form
  const formRef = useRef<HTMLFormElement>(null);

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  // Triggered more than once - use a reference variable to determine if component is mounted
  React.useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    // React advises to declare the async function directly inside useEffect
    async function loadStrategyOptions() {
      getStrategies()
        .then((response) => {
          if (response.successful === true && response.payload?.strategies !== null && response.payload.strategies.length > 0) {
            // Build list of strategy options, merge in details for strategy information modal
            // eslint-disable-next-line prefer-const
            let strategyOptionList: IStrategyOption[] = [];

            const strategyDetailsPromises: Promise<IBaseResponse<StrategyDetailDto>>[] = [];

            response.payload.strategies.forEach((strategy, index) => {
              const strategyOptionListItem: IStrategyOption = {
                strategyId: strategy.id,
                strategy: strategy,
                strategyDetail: null,
              };

              strategyOptionList.push(strategyOptionListItem);

              strategyDetailsPromises.push(getStrategyDetail(strategy.id));
            });

            Promise.all(strategyDetailsPromises)
              .then((results) => {
                results.forEach((strategyDetailResponse) => {
                  if (strategyDetailResponse.successful && strategyDetailResponse.payload) {
                    const item = strategyOptionList.find((x) => x.strategyId === strategyDetailResponse.payload.id);
                    if (item) item.strategyDetail = strategyDetailResponse.payload;
                  }
                });

                setStrategyOptions(strategyOptionList);
              })
              .catch((error) => {
                if (error) console.log(error);
              });
          } else {
            setErrorMessage(response.errorMessages.length < 1 ? defaultErrorMessage : response.errorMessages.toString());
            setHasError(true);
          }
        })
        .catch((error) => {
          setErrorMessage((error as AxiosError<IBaseResponse<StrategiesDto>>)?.response?.data?.errorMessages?.toString() || defaultErrorMessage);
          setHasError(true);
        });

      setIsLoading(false);
    }

    loadStrategyOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // User selected strategy for investment
  const onSubmit = async (values: IFormInput) => {
    onSuccess(values);
  };

  if (isLoading)
    return (
      <Center>
        <VStack mt="5%" w={{ base: '90vw', lg: '600px' }}>
          <BrandSpinner />
        </VStack>
      </Center>
    );

  return (
    <>
      <WindowAlert
        alertTitle="We are unable to process your request"
        alertMessage={errorMessage}
        alertFooter={`Please contact ${environment.SupportName}`}
        alertStatus="error"
        isOpen={hasError}
        onClose={() => setHasError(false)}
      />

      <StrategyInformationModal
        isCentered={isLargerThanTablet}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSelectStrategy={(selectedStrategy: StrategyDto) => onSubmit({ strategy: selectedStrategy })}
        strategy={modalStrategy}
        strategyDetail={modalStrategyDetail}
      />

      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Box w="100%">
          <VStack alignItems="stretch">{formatStrategyOptions(strategyOptions)}</VStack>
        </Box>
      </form>
    </>
  );
};
