import { Box, Flex, VStack, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { SideNav, TopNav } from '..';

export const OnboardLayout = () => {
  // https://v2.chakra-ui.com/docs/styled-system/responsive-styles
  const [isLargerThanTablet] = useMediaQuery('(min-width: 62em)');

  if (isLargerThanTablet)
    return (
      <Flex h="100vh">
        <SideNav />

        <Box flex={1} pt={6} px={6}>
          {/* This is the content for the route target */}
          <Outlet />
        </Box>
      </Flex>
    );

  return (
    <VStack w="100vw">
      <Box>
        <TopNav />
      </Box>

      <Box flex={1} pt={6} px={6}>
        {/* This is the content for the route target */}
        <Outlet />
      </Box>
    </VStack>
  );
};
