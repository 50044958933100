import { Button as ChakraButton, Text } from '@chakra-ui/react';
import { faClipboard } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

export interface ICopyToClipboardInput {
  content: string;
  displayConfirmation?: boolean;
}

const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (content: string) => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      console.log('Copied to clipboard:', content);
    } catch (error) {
      setIsCopied(false);
      console.error('Unable to copy to clipboard:', error);
    }
  };

  const resetConfirmation = () => {
    setIsCopied(false);
  };

  return { isCopied, copyToClipboard, resetConfirmation };
};

export const CopyToClipboardButton = ({ content, displayConfirmation = true }: ICopyToClipboardInput) => {
  const { isCopied, copyToClipboard, resetConfirmation } = useCopyToClipboard();

  return (
    <ChakraButton
      w="fit-content"
      color="blue.700"
      textStyle="text-sm-medium"
      background="transparent"
      padding={0}
      justifyContent="start"
      leftIcon={<FontAwesomeIcon icon={faClipboard} fontSize="12px" />}
      onClick={() => {
        copyToClipboard(content);
        window.setTimeout(() => {
          resetConfirmation();
        }, 5000);
      }}
    >
      {displayConfirmation && (
        <Text color="blue.700" textStyle="text-sm-medium">
          {isCopied ? 'Copied!' : 'Copy'}
        </Text>
      )}
    </ChakraButton>
  );
};
